import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from "react-router-dom";
// import { Provider } from "react-redux";
// import { store } from "./Components/redux/config/store";
// import { MsalProvider } from '@azure/msal-react';
// import { PublicClientApplication, EventType } from '@azure/msal-browser';
// import { MSAL_CONFIG } from './azure/authConfig';

// const msalInstance = new PublicClientApplication(MSAL_CONFIG);

// msalInstance.addEventCallback((event) => {
//   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//     const account = event.payload.account;
//     msalInstance.setActiveAccount(account);
//   }
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <BrowserRouter>
  //     <MsalProvider instance={msalInstance}>
  //       <Provider store={store}>
          <App />
  //       </Provider>
  //     </MsalProvider>
  //   </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
