import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Loader from "../../common/Loader";


export default function KeyInformationTable({ rows }) {
  // if (!rows || rows.length === 0) {
  //   return (
  //     <main className="other-relavent-container" style={{
  //         fontSize: "14px",
  //         color: "#666F8D",
  //         textAlign: "center",
  //         padding: "20px"
  //     }}>
  //         No relevant information available
  //     </main>
  // );
  // }
  if (!rows) {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Loader top={"50%"}/> {/* MUI CircularProgress component as a loader */}
        </div>
    );
}

  return (
    <TableContainer style={{ border: "none", paddingTop: "20px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index} // Use index as key if row?.name is not unique or undefined
            >
              <TableCell component="th" scope="row"
                sx={index === 0 ? { color: "#19213D", fontWeight: 'bold', paddingLeft: "30px", fontSize: "14px" } : { color: "#666F8D", paddingLeft: "30px", fontSize: "14px" }}
              >
                {row?.name}:
              </TableCell>
              <TableCell align="left"
                sx={index === 0 ? { color: "#19213D", borderLeft: '1px solid #E0E0E0', paddingLeft: "30px", fontSize: "14px" } : { color: "#666F8D", borderLeft: '1px solid #E0E0E0', paddingLeft: "30px", fontSize: "14px" }}
              >
                {row?.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}