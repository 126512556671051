import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filterOptions: {
        status: "",
        searchInput: "",
        selectedStartDate: "",
        selectedEndDate: ""
    }, 
    dashboardSearch : {
        searchInput: ""
    },
    dashboardTableData : {
        data: []
    },
    filterState: {
        isReset: false
    },
}


export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        updateFilterData: (state, action) => {
            state.filterOptions = {
                ...initialState.filterOptions,
                ...action.payload,
            };
        },
        updateDashboardSearch : (state,action) => {
            state.dashboardSearch = {
                ...initialState.dashboardSearch,
                ...action.payload
            }
        },
        updateDashboardTableData: (state,action) => {
            state.dashboardTableData = {
                ...initialState.dashboardTableData,
                ...action.payload
            }
        },
        updateFilterResetState: (state,action) => {
            state.filterState = {
                ...initialState.filterState,
                ...action.payload
            }
        }

    }
})


// Action creators are generated for each case reducer function
export const {
    updateFilterData,
    updateDashboardSearch,
    updateDashboardTableData,
    updateFilterResetState
} = dashboardSlice.actions;

export default dashboardSlice.reducer