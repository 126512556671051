import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Typography,
  Tooltip,
  Divider,
  TextField,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { getAccessData, submitAccessData } from "./service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import RotateRightIcon from "@mui/icons-material/RotateRight";

const RoleAssignment = (props) => {
  const { open, handleClose, cimID } = props;
  const [accessData, setAccessData] = useState([]);
  const [assignOwner, setAssignOwner] = useState([]);
  const [assignEditor, setAssignEditor] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const loggedInUser = JSON.parse(localStorage.getItem("userLoginInfo")).userEmail;

  const textFieldStyles = {
    "& .MuiInputLabel-root": {
      color: "#292F62",
      fontWeight: 600,
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      "& fieldset": { borderColor: "#C5CAE9" },
      "&:hover fieldset": { borderColor: "#292F62" },
      "&.Mui-focused fieldset": { borderColor: "#292F62" },
      padding: "4px 8px !important",
    },
    "& .MuiInputBase-input": {
      fontSize: 14,
      padding: "6px 8px",
    },
  };

  const labelStyles = {
    ...textFieldStyles["& .MuiInputBase-input"],
    padding: "6px 8px",
    width: "100%",
  };

  const populateData = async () => {
    try {
      setIsLoading(true);
      const response = await getAccessData(cimID);

      if (response?.status === "success") {
        setAccessData(response?.data);
        setAssignOwner(response?.data?.owner);
        setAssignEditor(response?.data?.editor);
        setInitialUsers([
          ...response?.data?.eligible_users,
          ...response?.data?.owner,
          ...response?.data?.editor,
        ]);
        setEligibleUsers(response?.data?.eligible_users);

        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const formData = {
        cim_id: cimID,
        owner: assignOwner,
        editor: assignEditor,
      };
      handleClose();
      const response = await submitAccessData(formData);

      if (response?.status === "success") {
        toast.success(response?.data?.message);
      } else {
        toast.warning(response?.data?.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [eligibleUsers, setEligibleUsers] = useState([]);
  const [initialUsers, setInitialUsers] = useState([]);

  useEffect(() => {
    populateData();
  }, []);

  useEffect(() => {
    const selectedIds = [
      ...assignOwner.map((user) => user.user_id),
      ...assignEditor.map((user) => user.user_id),
    ];

    const updatedEligibleUsers = initialUsers.filter(
      (user) => !selectedIds.includes(user.user_id)
    );

    setEligibleUsers(updatedEligibleUsers);
  }, [assignOwner, assignEditor, initialUsers]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: 3,
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          position: "relative",
        }}
      >
        <Typography variant="h5" sx={{ color: "#292F62" }}>
          Assign/Revoke Permissions
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "#292F62",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography
              variant="body2"
              sx={{ color: "#292F62", minWidth: 150 }}
            >
              Folder Name &nbsp; :
            </Typography>
            <Typography sx={labelStyles}>{accessData?.company_name}</Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography
              variant="body2"
              sx={{ color: "#292F62", minWidth: 150 }}
            >
              Created By &nbsp; :
            </Typography>
            <Typography sx={labelStyles}>{accessData?.created_by}</Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography
              variant="body2"
              sx={{ color: "#292F62", minWidth: 150 }}
            >
              {"Owner(s)"}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: 150,
                    },
                  },
                }}
                title={
                  <>
                    Owners will have full permissions on the reference
                    CIM/company, allowing them to perform:
                    <ul style={{ margin: 0, paddingLeft: "20px" }}>
                      <li>Manual edits</li>
                      <li>Redraft bullets</li>
                      <li>Add new bullets</li>
                      <li>Upload supporting documents</li>
                      <li>Assign ownership to other users</li>
                    </ul>
                  </>
                }
              >
                <IconButton sx={{ padding: 0 }}>
                  <InfoIcon sx={{ fontSize: 16, color: "#292F62" }} />
                </IconButton>
              </Tooltip>
              &nbsp; :
            </Typography>

            <Autocomplete
              multiple
              options={eligibleUsers}
              getOptionLabel={(option) => option.user_name}
              value={assignOwner}
              onChange={(event, newValue) => setAssignOwner(newValue)}
              disableCloseOnSelect
              clearIcon={null}
              sx={{ width: "100%" }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  const isRestrictedUser = option?.owner_revoke_flag === "no";
                  return (
                    <div
                      key={option?.owner_revoke_flag}
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        padding: "5px 10px",
                        backgroundColor: "#ebebeb",
                        borderRadius: "16px",
                        margin: "4px",
                        fontSize: 12,
                      }}
                    >
                      {option.user_name}
                      {!isRestrictedUser && (
                        <span
                          style={{
                            marginLeft: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // Remove the clicked user
                            const updatedValue = assignOwner.filter(
                              (user) => user.email_id !== option.email_id
                            );
                            setAssignOwner(updatedValue);
                          }}
                        >
                          &#x2715;
                        </span>
                      )}
                    </div>
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search or select users"
                  variant="outlined"
                  fullWidth
                  sx={textFieldStyles}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace") {
                      event.stopPropagation();
                    }
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography
              variant="body2"
              sx={{ color: "#292F62", minWidth: 150 }}
            >
              Editor(s){" "}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: 150,
                    },
                  },
                }}
                title={
                  <>
                    Editors will be granted access to the Two Pager, enabling
                    them to perform:
                    <ul style={{ margin: 0, paddingLeft: "20px" }}>
                      <li>Manual edits</li>
                      <li>Redraft bullets</li>
                      <li>Add new bullets</li>
                      <li>Upload supporting documents</li>
                    </ul>
                  </>
                }
              >
                <IconButton sx={{ padding: 0 }}>
                  <InfoIcon sx={{ fontSize: 16, color: "#292F62" }} />
                </IconButton>
              </Tooltip>
              &nbsp; :
            </Typography>

            <Autocomplete
              multiple
              options={eligibleUsers}
              getOptionLabel={(option) => option.user_name}
              value={assignEditor}
              onChange={(event, newValue) => setAssignEditor(newValue)}
              disableCloseOnSelect
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search or select users"
                  variant="outlined"
                  fullWidth
                  sx={textFieldStyles}
                />
              )}
            />
          </Box>
        </Box>
        {isLoading && (
          <Box
            className="loaderPage"
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 2000,
            }}
          >
            <RotateRightIcon
              color="inherit"
              fontSize="large"
              sx={{ animation: "spin 2s infinite ease-in-out" }}
            />
          </Box>
        )}
      </DialogContent>

      <Divider />
      <DialogActions sx={{ justifyContent: "flex-end", padding: 2 }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#292F62",
            color: "#fff",
            textTransform: "capitalize",
            ":hover": { backgroundColor: "#1F2656" },
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoleAssignment;
