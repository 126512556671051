import React from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002060",
        color: "#ffffff",
        fontWeight: 'bold',
        padding:12,
        minWidth: "125px",
        border: "1px solid #000000"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding:12,
        // color: "#666F8D",
        minWidth: "125px",
         border: "1px solid #000000"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(even)': {
    //     backgroundColor: "#f7f8f8",
    // },
    // // hide last border
    // '&:last-child td, &:last-child th': {
    //     border: 0,
    // },
}));

const PublicComps = ({ rows }) => {
    if (!rows || rows.length === 0) {
        return (
            <main className="other-relavent-container" style={{
                fontSize: "14px",
                color: "#000000",
                textAlign: "center",
                padding: "20px"
            }}>
                No relevant information available
            </main>
        );
    }

    return (
        <main>
            <TableContainer component={Paper} style={{ overflowX: "auto"}}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {Object.keys(rows[0]).map((item, index) => (
                                <StyledTableCell key={index}>{item}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rowIndex) => (
                            <StyledTableRow key={rowIndex}>
                                {Object.values(row).map((item, cellIndex) => (
                                    <StyledTableCell key={cellIndex}>{item}</StyledTableCell>
                                ))}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </main>
    );
};

export default PublicComps;