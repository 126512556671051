import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    keyInformation: [],
    businessDescription: [],
    keyMeritsConsideration: [],
    priliminaryInvestment: [],
    ownershipManagement: [],
    situationOverview: [],
    otherRelevantInfo: [],
    backToEditVersion: false,
    backToEditVersionId: "",
};

export const twoPagerSlice = createSlice({
    name: "twoPager",
    initialState,
    reducers: {
        updateKI: (state, action) => {
            state.keyInformation = [...initialState.keyInformation, action.payload];
        },
        updateBD: (state, action) => {
            state.businessDescription = [
                ...initialState.businessDescription,
                action.payload,
            ];
        },
        updateKMC: (state, action) => {
            state.keyMeritsConsideration = [
                ...initialState.keyMeritsConsideration,
                action.payload,
            ];
        },
        updatePI: (state, action) => {
            state.priliminaryInvestment = [
                ...initialState.priliminaryInvestment,
                action.payload,
            ];
        },
        updateOM: (state, action) => {
            state.ownershipManagement = [
                ...initialState.ownershipManagement,
                action.payload,
            ];
        },
        updateSO: (state, action) => {
            state.situationOverview = [
                ...initialState.situationOverview,
                action.payload,
            ];
        },
        updateORI: (state, action) => {
            state.otherRelevantInfo = [
                ...initialState.otherRelevantInfo,
                action.payload,
            ];
        },
        updateBTEV: (state, action) => {
            state.backToEditVersion = action.payload;
        },
        updateBTEVID: (state, action) => {
            state.backToEditVersionId = action.payload;
        },
        resetState: () => initialState,
    },
});

export const {
    updateKI,
    updateBD,
    updateKMC,
    updatePI,
    updateOM,
    updateSO,
    updateORI,
    updateBTEV,
    updateBTEVID,
    resetState
} = twoPagerSlice.actions;

export default twoPagerSlice.reducer;
