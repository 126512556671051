import React from "react";
import { styled } from "@mui/system";
import { Button, Typography } from "@mui/material";
import "jspdf-autotable";
import { getFile, getPDFFile } from "../Final2Pager/service";

const ExportToWordOrPdf = ({ data, record }) => {
  const isJsonDataEmpty = data.length === 0;

  const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#71A0D2",
    "&:hover": {
      backgroundColor: "#71A0D2",
    },
    padding: "12px",
  }));

  const downloadPDFFile = async (param) => {
    try {
      const response = await getPDFFile(param);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${record.companyName}_Two Pager_${formatDateFileName(
          new Date()
        )}_${record.version}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      // window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching generation status:", error);
    }
  };

  const downloadFile = async (param) => {
    try {
      const response = await getFile(param);
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${record.companyName}_Two Pager_${formatDateFileName(
          new Date()
        )}_${record.version}.doc`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error fetching generation status:", error);
    }
  };

  function formatDateFileName(inputDate) {
    // Create a new Date object from the input date string
    let date = new Date(inputDate);

    // Extract components of the date
    let month = date.getMonth() + 1; // getMonth() returns zero-based month
    let day = date.getDate();
    let year = date.getFullYear();

    // Ensure month and day are formatted to have leading zeros if necessary
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    // Concatenate the formatted date in MM.DD.YYYY format
    return month + "." + day + "." + year;
  }

  return (
    <div>
      <article className="export-btn-container">
        <aside>
          <ColorButton
            variant="contained"
            // startIcon={<UploadFileOutlinedIcon />}
            style={{
              height: "35px",
              textTransform: "none",
            }}
            onClick={() => downloadPDFFile(record.two_pager_id)}
            disabled={isJsonDataEmpty}
          >
            <Typography className="create-new-doc-btn-text">PDF </Typography>
          </ColorButton>
        </aside>
        <aside>
          <ColorButton
            variant="contained"
            // startIcon={<UploadFileOutlinedIcon />}
            style={{
              height: "35px",
              textTransform: "none",
            }}
            onClick={() => downloadFile(record.two_pager_id)}
            disabled={isJsonDataEmpty}
          >
            <Typography className="create-new-doc-btn-text"> Word</Typography>
          </ColorButton>
        </aside>
      </article>
    </div>
  );
};

export default ExportToWordOrPdf;
