import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";

const DisabledBackground = styled(Box)({
  width: "100%",
  height: "100%",
  position: "fixed",
  background: "#ccc",
  opacity: 0.5,
  zIndex: 99,
});

const CircularLoading = ({top}) => (
  <>
    <CircularProgress
      size={40}
      sx={{
        position: "fixed",
        left: "50%",
        top,
        transform: "translate(-50%, -50%)",
        zIndex: 999,
      }}
    />
    <DisabledBackground />
  </>
);

export default CircularLoading;