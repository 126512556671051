import React, { useEffect, useState, version } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Tooltip,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import ExportToWordOrPdf from "./ExportToWordOrPdf";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Accordian = ({ accordianData, jsonData }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setRows([]);
    setLoading(true); // Start loading
    // Simulate data loading
    const loadData = setTimeout(() => {
      setRows(accordianData);

      setLoading(false); // Data is loaded
    }, 2000); // Simulate a 2-second loading time

    return () => clearTimeout(loadData); // Cleanup timeout on unmount
  }, [accordianData]);

  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);

  const handleCheckboxChange = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = selected
        .slice(0, selectedIndex)
        .concat(selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const [exportDetails, setExportDetails] = useState([]);

  const formatDate = (timestamp) => {
    try {
      // Split the timestamp into date and time parts
      const [datePart, timePart] = timestamp.split("_");

      // Remove milliseconds from timePart
      const [time] = timePart.split(".");

      // Create a new Date object without milliseconds
      const date = new Date(`${datePart}T${time}Z`);
      // console.log(datePart,"datedatedatedate",time)

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid Date");
      }

      // Options for formatting the date and time
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };

      // Format the date and time
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date
      );

      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid date";
    }
  };

  const handleView2PagerDoc = (two_pager_id, doc, version) => {
    navigate(`/view-generate-2pager/${two_pager_id}`, {
      state: { doc: doc, version: version },
    });
  };

  const handleCreateNewDoc = (two_pager_id, doc, version, status) => {
    navigate(`/create-new-doc/${two_pager_id}`, {
      state: { doc: doc, version: version, status: status },
    });
  };

  const getStatusColor = (value, bool) => {
    if (bool === true) {
      return "#fff";
    }
    if (value === "Completed") {
      return "#26B144";
    }
    if (value === "In Progress") {
      return "#DD9A19";
    }
    if (value === "Failed") {
      return "#FF4E28";
    }
    return "";
  };

  const handleCopy = (textToCopy) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success("Text copied to clipboard!", {
            position: "top-right",
            autoClose: 3000,
          });
        })
        .catch((error) => {
          toast.error("Failed to copy text!", {
            position: "top-right",
            autoClose: 3000,
          });
        });
    } else {
      // Fallback for older browsers
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        toast.success("Text copied to clipboard!", {
          position: "top-right",
          autoClose: 3000,
        });
      } catch (err) {
        toast.error("Failed to copy text!", {
          position: "top-right",
          autoClose: 3000,
        });
      }
      document.body.removeChild(textarea);
    }
  };

  return (
    <>
      {loading ? (
        <Loader top={"50%"} />
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "none",
            }}
          >
            {rows.length === 0 && !loading ? (
              <p
                style={{
                  marginTop: "50px",
                  color: "#ee6b6b",
                  textAlign: "center",
                }}
              >
                No data to display!
              </p>
            ) : (
              <Table>
                <TableHead style={{ backgroundColor: "#dae6f1" }}>
                  <TableRow>
                    <TableCell style={{ color: "#292F62" }}>
                      Processing Status
                    </TableCell>
                    <TableCell style={{ color: "#292F62" }}>
                      Company Name
                    </TableCell>
                    <TableCell style={{ color: "#292F62" }}>
                      Document Name
                    </TableCell>
                    <TableCell style={{ color: "#292F62" }}>
                      Document ID
                    </TableCell>
                    <TableCell style={{ color: "#292F62" }}>Version</TableCell>
                    <TableCell style={{ color: "#292F62" }}>
                      Time (EST)
                    </TableCell>
                    {rows[0]?.generatedBy !== undefined && (
                      <TableCell style={{ color: "#292F62" }}>
                        Generated By
                      </TableCell>
                    )}
                    <TableCell style={{ color: "#292F62" }}>
                      Modified By
                    </TableCell>
                    <TableCell style={{ color: "#292F62" }}>
                      View/Edit
                    </TableCell>
                    <TableCell style={{ color: "#292F62" }}>Export</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.two_pager_id_display}
                      hover
                      role="checkbox"
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#F7F8FA" : "#ffffff",
                      }}
                    >
                      <TableCell
                        style={{
                          border: "none",
                          color: `${getStatusColor(row.status)}`,
                        }}
                      >
                        {row.status}
                      </TableCell>
                      <TableCell style={{ border: "none" }}>
                        {row.companyName}
                      </TableCell>
                      <TableCell style={{ border: "none" }}>
                        {row.docName}
                      </TableCell>
                      <TableCell
                        style={{ border: "none" }}
                        onClick={() => handleCopy(row.two_pager_id)}
                      >
                        <Tooltip title={row.two_pager_id}>
                          <span>{row.two_pager_id.substring(0, 8)}....</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{ border: "none" }}>
                        {row.version}
                      </TableCell>
                      <TableCell style={{ border: "none" }}>
                        {formatDate(row.timeModified)}
                      </TableCell>
                      {row?.generatedBy !== undefined ? (
                        <TableCell style={{ border: "none" }}>
                          {row?.generatedBy}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      <TableCell style={{ border: "none" }}>
                        {row?.modifiedBy}
                      </TableCell>
                      {row.status === "Completed" ? (
                        <TableCell style={{ border: "none" }}>
                          <RemoveRedEyeIcon
                            style={{ fontSize: 15, cursor: "pointer" }}
                            onClick={() =>
                              handleView2PagerDoc(
                                row.two_pager_id,
                                row.companyName,
                                row.version
                              )
                            }
                          />
                        </TableCell>
                      ) : (
                        <TableCell style={{ border: "none" }}>
                          <RemoveRedEyeIcon
                            style={{ fontSize: 15, cursor: "pointer" }}
                            onClick={() =>
                              handleCreateNewDoc(
                                row.two_pager_id,
                                row.companyName,
                                row.version,
                                row.status
                              )
                            }
                          />
                        </TableCell>
                      )}
                      <TableCell style={{ border: "none" }}>
                        <ExportToWordOrPdf data={accordianData} record={row} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </>
      )}
    </>
  );
};

export default Accordian;
