import React, { useState, useEffect } from "react";
import "./index.css"
import _  from "lodash"

export default function CustomTable(props) {
const {data, section} = props
const [finalData, setFinalData] = useState([]);
const selectedItems = [
  "Company Name",
  "Sector",
  "Furthest Stage",
  "Platform / Add-on",
  "Add-on To",
  "Pass Details",
  "Revenue",
  "Revenue Multiple",
  "EBITDA",
  "EBITDA Margin (%)",
  "EBITDA Multiple"]

  function filterItems(data, selectedItems) {
    return data?.map(obj => {
        const newObj = {};
        Object.keys(obj).forEach(key => {
            if (selectedItems.includes(key)) {
                newObj[key] = obj[key];
            }
        });
        return newObj;
    });
  }
  
  useEffect(() => {
    if (section === "pastSimilarLooks") {
      const filteredRecords = filterItems(data, selectedItems);
      setFinalData(filteredRecords)
    }
    else{
      setFinalData(data)
    }
  }, [data]);

    return (
        <>
      {finalData?.length > 0 &&     <table className="pdfTable">
  <tr>
          {Object?.keys(finalData[0])?.map((item, index) => (
                            <th>{item}</th>
                            ))}
  </tr>

  {finalData?.map((row, index) => (
                              <tr>
                                {Object?.values(row).map((item, index) => (
                                    <td key={index}>{item}</td>
                                ))}
                           </tr>
                        ))}
 
</table>}
        </>
    );
}