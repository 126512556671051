import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Tab,
  Tabs,
  DialogActions,
  DialogContent,
  Dialog,
  Tooltip,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useParams } from "react-router-dom";
import { getCimPage, getFeedback, view2PagerLists } from "../service";
import ReactMarkdown from "react-markdown";
import MarkdownIt from "markdown-it";
import "../index.css";
import Loader from "../../common/Loader";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import _ from "lodash";

const md = new MarkdownIt();
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const BusinessDescription = ({ data, section }) => {
  const { companyName, data: details } = data;
  const urlParams = useParams();
  const params = urlParams.userId;
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [subHovered, setSubHovered] = useState(null);
  const [selectedItemName, setSelectedItemName] = useState([]);
  const [value, setValue] = React.useState(0);
  const [base64, setBase64] = useState("");
  const [pageNumber, setPageNumber] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [thumbs, setThumbs] = useState({});
  const [businessDesc, setBusinessDesc] = useState([]);
  const [dataSource, setDataSource] = useState();
  const firstButtonRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState("");
  const [adminEdit, setAdminEdit] = useState("");

  const transformBusinessDescData = (param) => {
    const data = param.bullets;
    const transformBullets = (bullets) => {
      return bullets?.map((bullet) => {
        let bulletObject = {
          name: bullet.name,
          description:
            bullet?.values?.length > 0 ? bullet?.values[0]?.result : null,
          page: bullet?.values?.length > 0 ? bullet?.values[0]?.page_no : null,
          number: bullet.number,
          feedback: bullet?.feedback,
          data_source:
            bullet?.values?.length > 0 ? bullet?.values[0]?.data_source : null,
          url: bullet?.values?.length > 0 ? bullet?.values[0]?.url : null,
        };

        if (bullet.sub_bullets && bullet.sub_bullets?.length > 0) {
          bulletObject.subDescription = bullet.sub_bullets?.map(
            (subBullet) => ({
              name: subBullet.name,
              description:
                subBullet?.values?.length > 0
                  ? subBullet?.values[0]?.result
                  : null,
              page:
                subBullet?.values?.length > 0
                  ? subBullet?.values[0]?.page_no
                  : null,
              number: subBullet.number,
              feedback: subBullet?.feedback,
              data_source:
                subBullet?.values?.length > 0
                  ? subBullet?.values[0]?.data_source
                  : null,
              url:
                subBullet?.values?.length > 0
                  ? subBullet?.values[0]?.url
                  : null,
            })
          );
        }

        return bulletObject;
      });
    };

    const transformedBullets = transformBullets(data);
    return transformedBullets;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedTab(event.target.innerText);
    getCimPageApi(
      params,
      _.find(selectedItemName, (item) =>
        item?.title?.includes(event.target.innerText)
      ).pageNo[0],
      _.find(selectedItemName, (item) =>
        item?.title?.includes(event.target.innerText)
      ).value
    );
  };
  useEffect(() => {
    // Auto-click the first button when the component mounts
    if (firstButtonRef.current) {
      firstButtonRef.current.click();
    }
  }, []);

  const handleThumbsToggle = async (index, type, number, subNumber) => {
    try {
      const response = await getFeedback(
        params,
        "II",
        number,
        subNumber,
        type ? "True" : "False"
      );
      // Handle response if necessary
      if (response.status === "success") {
        setLoader(true);

        const resp = await view2PagerLists(2, params);
        const result = transformBusinessDescData(resp.data);
        setBusinessDesc(result);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error triggering generation:", error);
    }

    setThumbs((prevThumbs) => ({
      ...prevThumbs,
      [index]: prevThumbs[index] === type ? null : type,
    }));
  };

  const handleClickOpen = (name, dataSource) => {
    if (dataSource === "CIM") {
      const sortedData = _.orderBy(
        name,
        [(item) => (item?.title === "CIM" ? 0 : 1)],
        ["asc"]
      );
      setSelectedItemName(sortedData);
      setSelectedTab(sortedData[0]?.title);
      if (checkNumber(sortedData[0]?.pageNo[0])) {
        getCimPageApi(params, sortedData[0]?.pageNo[0], sortedData[0]?.value);
      }
    } else {
      setSelectedItemName(name);
      setSelectedTab(name[0]?.title);
    }

    setOpen(true);
    setDataSource(dataSource);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      setSelectedItemName(null);
      setPageNumber(null);
      setValue(0);
      // setSelectedTab("CIM");
    }
  };

  const getCimPageApi = async (twoPagerId, pageNu, value) => {
    setPageNumber(pageNu);
    setLoading(true);
    try {
      const response = await getCimPage(twoPagerId, pageNu, value);
      setBase64(response?.data?.base64_format);
    } catch (response) {
      console.error("Error triggering generation:", response);
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = () => {
    setIsOpen(true);
  };

  const handleClosePopUp = (e) => {
    if (e.target.className === "popup") {
      setIsOpen(false);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);

      const resp = await view2PagerLists(2, params);
      setAdminEdit(resp?.edit);
      const result = transformBusinessDescData(resp?.data);
      setBusinessDesc(result);
      setLoader(false);
    };

    fetchData();
  }, []);

  useEffect(() => {}, [businessDesc]);

  const renderList = (items, section, parentIndex = "") => {
    return (
      <>
        {items?.map((item, index) => {
          const uniqueIndex = `${parentIndex}-${index}`;
          return (
            <>
              {section === "final2pager" ? (
                <></>
              ) : (
                <span
                  onMouseEnter={() =>
                    setSubHovered(`infoIconSub${uniqueIndex}`)
                  }
                  onMouseLeave={() => setSubHovered(null)}
                  style={{ fontWeight: 500, textDecoration: "underline" }}
                >
                  {item?.name}:
                  {item?.data_source && (
                    <>
                      <Tooltip title="References">
                        {item?.data_source === "EXTERNAL" &&
                          item?.url?.length > 0 && (
                            <InfoIcon
                              onClick={() =>
                                handleClickOpen(item?.url, item?.data_source)
                              }
                              style={{
                                cursor: "pointer",
                                opacity:
                                  subHovered === `infoIconSub${uniqueIndex}`
                                    ? 1
                                    : 0.3,
                                transition: "opacity 0.3s",
                                verticalAlign: "middle",
                                marginLeft: "1%",
                              }}
                              className={`infoIconSub${uniqueIndex}  ${"cssClassData"}`}
                            />
                          )}
                        {item?.data_source === "CIM" &&
                          item?.page?.length > 0 && (
                            <InfoIcon
                              onClick={() =>
                                handleClickOpen(item?.page, item?.data_source)
                              }
                              style={{
                                cursor: "pointer",
                                opacity:
                                  subHovered === `infoIconSub${uniqueIndex}`
                                    ? 1
                                    : 0.3,
                                transition: "opacity 0.3s",
                                verticalAlign: "middle",
                                marginLeft: "1%",
                              }}
                              className={`infoIconSub${uniqueIndex}  ${"cssClassData"}`}
                            />
                          )}
                      </Tooltip>
                     {adminEdit === "yes" && <IconButton
                        onClick={() =>
                          handleThumbsToggle(
                            index,
                            true,
                            parentIndex + 1,
                            item?.number
                          )
                        }
                        style={{
                          color:
                            item?.feedback === true ? "#7AD167" : "#0000001f",
                        }}
                      >
                        <ThumbUpIcon style={{ fontSize: "large" }} />
                      </IconButton>}
                     {adminEdit === "yes" && <IconButton
                        onClick={() =>
                          handleThumbsToggle(
                            index,
                            false,
                            parentIndex + 1,
                            item?.number
                          )
                        }
                        style={{
                          color:
                            item?.feedback === false ? "#eb5050" : "#0000001f",
                        }}
                      >
                        <ThumbDownIcon style={{ fontSize: "large" }} />
                      </IconButton>}
                    </>
                  )}
                </span>
              )}
              <ul style={{ listStyleType: "disc" }}>
                <li key={index} style={{ display: "flex" }}>
                  <ReactMarkdown children={item?.description} components={md} />
                  {item?.subDescription &&
                    renderList(item?.subDescription, section, uniqueIndex)}
                </li>
              </ul>
            </>
          );
        })}
      </>
    );
  };

  function convertToNumbers(pageNumbers) {
    const convertedNumbers = [];

    pageNumbers.forEach((page) => {
      const number = parseFloat(page);
      if (!isNaN(number)) {
        convertedNumbers.push(Math.floor(number));
      } else {
        console.log(`Cannot convert '${page}' to a number, skipping.`);
      }
    });

    return convertedNumbers;
  }

  function checkNumber(value) {
    let number = parseFloat(value); // Attempt to convert the string to a number (float)

    if (isNaN(number)) {
      return; // If conversion fails, return without doing anything
    }

    return Math.floor(number); // Convert to integer by truncating any decimal part
  }
  return (
    <>
      {loader ? (
        <Loader top={"50%"} />
      ) : (
        <main className="markdownArea">
          <Typography
            style={{ fontSize: "15px", color: "#19213D", fontWeight: "bold" }}
          >
            {companyName}
          </Typography>
          <section
            style={{ color: "#666F8D", fontSize: "14px", textAlign: "justify" }}
          >
            {businessDesc?.length > 0 &&
              businessDesc?.map((item, index) => {
                return (
                  <Box component="div" className="markdownBox">
                    {section === "final2pager" ? (
                      <></>
                    ) : (
                      <span
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(null)}
                        style={{ fontWeight: 500, textDecoration: "underline" }}
                      >
                        {console.log(businessDesc)}
                        {item?.name}:
                        {item?.data_source && (
                          <>
                            <Tooltip title="References">
                              {item?.data_source === "EXTERNAL" &&
                                item?.url?.length > 0 && (
                                  <InfoIcon
                                    onClick={() =>
                                      handleClickOpen(
                                        item?.url,
                                        item?.data_source
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      opacity: hovered === index ? 1 : 0.3,
                                      transition: "opacity 0.3s",
                                      verticalAlign: "middle",
                                      marginLeft: "1%",
                                    }}
                                    className={`infoIconDesc${index} ${"cssClassData"}`}
                                  />
                                )}
                              {item?.data_source === "CIM" &&
                                item?.page?.length > 0 && (
                                  <InfoIcon
                                    onClick={() =>
                                      handleClickOpen(
                                        item?.page,
                                        item?.data_source
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      opacity: hovered === index ? 1 : 0.3,
                                      transition: "opacity 0.3s",
                                      verticalAlign: "middle",
                                      marginLeft: "1%",
                                    }}
                                    className={`infoIconDesc${index} ${"cssClassData"}`}
                                  />
                                )}
                            </Tooltip>

                           {adminEdit === "yes" && <IconButton
                              onClick={() =>
                                handleThumbsToggle(
                                  index,
                                  true,
                                  item?.number,
                                  null
                                )
                              }
                              style={{
                                color:
                                  item?.feedback === true
                                    ? "#7AD167"
                                    : "#0000001f",
                              }}
                            >
                              <ThumbUpIcon style={{ fontSize: "medium" }} />
                            </IconButton>}

                            {adminEdit === "yes" && <IconButton
                              onClick={() =>
                                handleThumbsToggle(
                                  index,
                                  false,
                                  item?.number,
                                  null
                                )
                              }
                              style={{
                                color:
                                  item?.feedback === false
                                    ? "#eb5050"
                                    : "#0000001f",
                              }}
                            >
                              <ThumbDownIcon style={{ fontSize: "medium" }} />
                            </IconButton>}
                          </>
                        )}
                      </span>
                    )}

                    <ul>
                      <li
                        key={index}
                        style={{ marginTop: "10px", position: "relative" }}
                      >
                        <ReactMarkdown
                          children={item?.description}
                          components={md}
                        />

                        {renderList(item?.subDescription, section, index)}
                      </li>
                    </ul>
                  </Box>
                );
              })}
          </section>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                height: "500px",
                width: "700px",
              },
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                margin: "1%",
                cursor: "pointer",
              }}
            >
              <HighlightOffIcon onClick={handleClose} />
            </Grid>
            {/* <DialogTitle>Information {selectedItemName}</DialogTitle> */}
            <DialogContent>
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                  height: 224,
                }}
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{
                    borderRight: 1,
                    borderColor: "divider",
                    "& .Mui-selected": {
                      color: "#292f62",
                      fontWeight: 700,
                    },
                  }}
                >
                  {selectedItemName?.map((item, index) => (
                    <Tab
                      key={item?.title}
                      label={item?.title}
                      {...a11yProps(selectedItemName?.indexOf(item))}
                      style={{ fontWeight: 700, textTransform: "none" }}
                    />
                  ))}
                </Tabs>

                {selectedItemName?.map((tab, index) => (
                  <TabPanel key={index} value={value} index={index}>
                    {dataSource === "EXTERNAL" ? (
                      <>
                        <Grid
                          style={{
                            fontSize: "14px",
                            display: "flex",
                            textWrap: "nowrap",
                          }}
                        >
                          <p style={{ fontWeight: "bold" }}>Source :</p>
                          <p> URL</p>
                        </Grid>

                        <Grid
                          className="pageNumber"
                          style={{
                            maxWidth: "460px",
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            flexFlow: "wrap",
                            fontSize: "14px",
                            marginBottom: "10px",
                          }}
                        >
                          <p style={{ margin: "0%", fontWeight: "bold" }}>
                            URL(s) :
                          </p>
                          {selectedItemName?.map((item, index) => (
                            <ul style={{ margin: "0%" }}>
                              <li key={index}>
                                <div>
                                  <a
                                    href="#"
                                    onClick={() => window.open(item, "_blank")}
                                  >
                                    {item}
                                  </a>
                                </div>
                              </li>
                            </ul>
                          ))}
                        </Grid>
                      </>
                    ) : (
                      <>
                        {/* <Grid
                          style={{
                            fontSize: "14px",
                            display: "flex",
                            textWrap: "nowrap",
                          }}
                        >
                          <p
                            style={{ paddingRight: "64px", fontWeight: "bold" }}
                          >
                            Source
                          </p>
                          <p>: CIM Document</p>
                        </Grid> */}

                        <Grid
                          className="pageNumber"
                          style={{
                            maxWidth: "460px",
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            flexFlow: "wrap",
                            fontSize: "14px",
                            marginBottom: "10px",
                          }}
                        >
                          <p style={{ margin: "0%", fontWeight: "bold" }}>
                            Page Number(s) :
                          </p>
                          {_.find(selectedItemName, (item) =>
                            item?.title?.includes(selectedTab)
                          ) ? (
                            <>
                              {convertToNumbers(
                                _.find(selectedItemName, (item) =>
                                  item?.title?.includes(selectedTab)
                                ).pageNo
                              )?.map((item, index) => (
                                <div
                                  key={index}
                                  className="circular-button"
                                  onClick={() =>
                                    getCimPageApi(
                                      params,
                                      item,
                                      _.find(selectedItemName, (item) =>
                                        item?.title?.includes(selectedTab)
                                      ).value
                                    )
                                  }
                                >
                                  {item}
                                </div>
                              ))}
                            </>
                          ) : (
                            <p style={{ margin: "0%" }}>
                              {" "}
                              Relevant Data Not Found.
                            </p>
                          )}
                        </Grid>
                      </>
                    )}
                    <div>
                      {pageNumber ? (
                        <>
                          <div>
                            {loading ? (
                              <Loader top={"50%"} />
                            ) : (
                              <>
                                <img
                                  src={"data:image/png;base64," + base64}
                                  alt="Selected"
                                  style={{ width: "370px", cursor: "pointer" }}
                                  onClick={handleImageClick}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontSize: "14px",
                                  }}
                                >
                                  <div>Click on image to enlarge</div>
                                  <div>page {pageNumber}</div>
                                </div>
                              </>
                            )}
                          </div>

                          {isOpen && (
                            <div className="popup" onClick={handleClosePopUp}>
                              <div className="popup-content">
                                <div class="scrollable-content">
                                  <img
                                    src={"data:image/png;base64," + base64}
                                    alt="Selected"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </TabPanel>
                ))}
              </Box>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </main>
      )}
    </>
  );
};

export default BusinessDescription;
