import { LogLevel } from "@azure/msal-browser";

let RedirectUri = `${window.location.protocol}//${window.location.hostname}`;
if (window.location.port !== "") {
  RedirectUri = `${RedirectUri}:${window.location.port}`;
}
console.log("process.env.REACT_APP_PUBLIC_APPLICATION_ID", process.env.REACT_APP_PUBLIC_APPLICATION_ID)
console.log("process.env.REACT_APP_PUBLIC_TENANT_ID}", process.env.REACT_APP_PUBLIC_TENANT_ID)
console.log("RedirectUri", RedirectUri)

export const MSAL_CONFIG = {
  auth: {
    clientId: process.env.REACT_APP_PUBLIC_APPLICATION_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_PUBLIC_TENANT_ID}`,
    redirectUri: RedirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    "User.Read",
    "User.ReadBasic.All",
    "profile", "email"],
};
