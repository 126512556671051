import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "./style.css"
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from '@mui/material/styles';
import { Typography } from "@mui/material";
import rightSwiftIcon from "../../Assets/Icons/right-swift.svg"
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from "react-redux";
import { updateFilterData, updateDashboardSearch, updateFilterResetState } from "../redux/reducer/dashboardSlice"
import RefreshIcon from '@mui/icons-material/Refresh';
import dayjs from 'dayjs';

const StyledSelect = styled(Select)(({ theme }) => ({
    '& select': {
        borderColor: '#DFDFDF', // Set the border color to light grey
        borderWidth: '1px', // Set the border width
    },
}));

const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#292F62",
    '&:hover': {
        backgroundColor: "#292F62",
    },
    padding: "12px",

}));
const customTheme = createTheme({
    components: {
        MuiInputLabel: {
            defaultProps: {
                sx: {
                    fontSize: "13px",
                    color: "#292F62"
                },
            },
        },
    },

});

const Filter = () => {

    const dispatch = useDispatch();
    const [status, setStatus] = React.useState('');
    const [searchInput, setSearchInput] = React.useState('');
    const [selectedStartDate, setSelectedStartDate] = React.useState(null);
    const [selectedEndDate, setSelectedEndDate] = React.useState(null);

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
        // setSearchTerm(event.target.value); // Update search term
    };

    const handleSearch = () => {
        console.log(searchInput);
        dispatch(updateDashboardSearch({ searchInput }))

    }
    const handleSearchKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
          handleSearch();
        }
      }

    const handleStartDateChange = (newValue) => {
        setSelectedStartDate(newValue);
    };

    const handleEndDateChange = (newValue) => {
        setSelectedEndDate(newValue);
    };

    const handleApplyFilter = () => {
        dispatch(updateFilterData({ status, searchInput, selectedStartDate, selectedEndDate }))
    };

    const handleResetFilter = () => {
        setSearchInput("");
        setSelectedStartDate(null)
        setStatus("")
        setSelectedEndDate(null)
        dispatch(updateFilterResetState({ isReset: true }))
    }


    return (
        <ThemeProvider theme={customTheme}>
            <section className="filter-container">
                <FormControl sx={{ m: 1, minWidth: 120, '& .MuiOutlinedInput-notchedOutline': { borderColor: '#DFDFDF' } }} size="small">
                    <InputLabel id="demo-select-small-label" >Status</InputLabel>
                    <StyledSelect
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={status}
                        label="Status"
                        style={{ height: 40 }}
                        onChange={handleStatusChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={"completed"}>Completed</MenuItem>
                        <MenuItem value={"in progress"}>In Progress</MenuItem>
                        <MenuItem value={"failed"}>Failed</MenuItem>
                    </StyledSelect>
                </FormControl>

                <Paper
                    component="form"
                    sx={{ width: 500, height: 40, marginTop: "7px", boxShadow: "none", border: "1px solid #DFDFDF" }}
                >
                    <IconButton sx={{ p: '10px' }} aria-label="menu">
                        <SearchIcon />
                    </IconButton>
                    <InputBase
                        value={searchInput}
                        onChange={handleSearchChange}
                        style={{ width: "364px" }}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Document Name"
                        inputProps={{ 'aria-label': 'search google maps', style: { fontSize: 13 } }}
                         onKeyDown={handleSearchKeyPress}
                    />

                    {/* <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"> */}
                    <ColorButton variant="contained"
                        onClick={() => handleSearch()}
                        style={{ textTransform: 'none', padding: "21px", height: "41px", marginTop: "-3px", }}>
                        <Typography className="create-new-doc-btn-text">
                            Search
                        </Typography>

                    </ColorButton>
                    {/* </IconButton> */}
                </Paper>

                {/* <article className="date-picker-container"> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker label="Start Date"
                            // className={classes. datePicker}
                            value={selectedStartDate}
                            maxDate={dayjs()} // Prevent selecting dates in the future
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: '#DFDFDF', // Set border color to light grey
                                },
                                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                    fontSize: "13px",
                                    height: "40px",
                                    width: "160px",
                                    overflow: "hidden"
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#878790',
                                    marginTop: "-4px",
                                },

                            }}



                        />
                    </DemoContainer>
                </LocalizationProvider>

                <img src={rightSwiftIcon} alt="Right Swift Icon" style={{
                    marginLeft: "-40px",
                    marginRight: "6px"
                }} />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker label="End Date"
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            minDate={selectedStartDate} // Prevent selecting dates before the start date
                            maxDate={dayjs()} // Prevent selecting dates in the future
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: '#DFDFDF', // Set border color to light grey
                                },
                                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                    fontSize: "13px",
                                    height: "40px",
                                    width: "160px",
                                    overflow: "hidden"
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#878790',
                                    marginTop: "-4px",
                                },

                            }}
                            style={{ marginRight: '-8px' }}
                        />
                    </DemoContainer>
                </LocalizationProvider>
                {/* </article> */}
                <ColorButton variant="contained"
                    onClick={() => handleApplyFilter()}
                    startIcon={<FilterAltOutlinedIcon />} style={{ height: "39px", textTransform: 'none', marginLeft: "-30px", marginTop: "8px" }}>

                    <Typography className="create-new-doc-btn-text">  Filter</Typography>
                </ColorButton>
                <ColorButton variant="contained"
                    onClick={() => handleResetFilter()}
                    startIcon={<RefreshIcon />} style={{ height: "39px", textTransform: 'none', marginTop: "8px" }}>

                    <Typography className="create-new-doc-btn-text">Reset</Typography>
                </ColorButton>


            </section>
        </ThemeProvider>
    )

}

export default Filter