import React, { useEffect, useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import "./style.css"
import Filter from "./Filter";
import FilterTable from "./Table"
import homeIcon from "../../Assets/Icons/homeIcon.svg"
import fileIcon from "../../Assets/Icons/file-icon.svg"
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Divider from '@mui/material/Divider';
import {
    Box,
    Paper,
    Typography,
    Button

} from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Header from "../drawer/Header"
import { updateBTEV } from "../redux/reducer/twoPagerSlice";
import { useDispatch } from "react-redux";

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [releaseVersion, setReleaseVersion] = useState("NA");
    const ColorButton = styled(Button)(({ theme }) => ({
        backgroundColor: "#292F62",
        '&:hover': {
            backgroundColor: "#292F62",
        },
        padding: "12px",

    }));

    const handleCreateNewDoc = () => {
        navigate("/create-new-doc", {
          state: "NewGeneration",
        });
      };

    useEffect(() => {
        localStorage.setItem("section", 0);
        localStorage.setItem("acceptedAll", "false");
        dispatch(updateBTEV(false))
        setReleaseVersion(JSON.parse(localStorage.getItem("releaseVersion")))
    }, [releaseVersion]);

    return (
        <>
            <main style={{ textAlign: "center" }}>
                <Header />
                <section className="dashboard-heading">
                    <article className="home-page-left-heding">
                        <img src={homeIcon} alt="home-icon" />
                        <Typography className="home-page-heading">Home</Typography>
                    </article>

                    <ColorButton variant="contained"
                        style={{ textTransform: 'none' }}
                        startIcon={<FileCopyOutlinedIcon />}
                        onClick={() => handleCreateNewDoc()}
                    >
                        <Typography className="create-new-doc-btn-text" > Create New Document</Typography>
                    </ColorButton>
                </section>
                <Divider style={{
                    background: "rgb(255 255 255)", margin: " 10px 47px 50px 47px",
                    boxShadow: "1px 1px 1px #e8e2e2"
                }} />
                <Filter />

                <FilterTable />
                <Typography style={{ padding: 30, fontSize: 14, textAlign: "center" }}>
                    &#169; All Rights Reserved (2024) Veritas Capital : Release Version :&nbsp;
                    <Typography component='span' style={{ cursor: "pointer", color: "#33348e", fontSize: 14 }} onClick={() => {
                        window.open(`${window.location.origin}/release_notes`, '_blank')
                    }}>
                        {releaseVersion}
                    </Typography>
                </Typography>
            </main>
        </>
    )

}

export default Dashboard