import React, { useEffect } from 'react';
import VeritasLogo from "../../Assets/Icons/veritasLogo.svg";
import "./login.css";
import { Box, Paper, Typography, Button } from "@mui/material";
import microsoftIcon from "../../Assets/Icons/microsoftIcon.svg";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import loginBackground from "../../Assets/Icons/login-background.png";
import { login } from "./service"
import { loginRequest } from "../../azure/authConfig";
import { useMsal, useIsAuthenticated, } from "@azure/msal-react";

const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#19213D",
    '&:hover': {
        backgroundColor: "#19213D",
    },
    marginTop: "45px",
    marginBottom: "40px",
    width: "255px"
}));

const Login = () => {

    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    console.log("isAuthenticated----", isAuthenticated)
    function handleLogin(loginType) {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch((e) => {
                console.error(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.error(e);
            });

        }
        // handleRedirect()
    }

    return (
        <main style={{ background: `url(${loginBackground})`, backgroundSize: "cover", textAlign: "center" }}>
            <Box
                sx={{
                    '& > :not(style)': {
                        m: 1,
                        width: 353,
                        height: 545,
                        margin: 0
                    },
                }}
                className="loginBox"
            >
                <Paper>
                    <img src={VeritasLogo} alt="Veritas-Login-Logo" className='logo' />
                    <Typography className="sign-text">
                        Sign in using Microsoft credentials
                    </Typography>
                    <ColorButton variant="contained" loading={inProgress === "login"}

                        onClick={() => handleLogin("redirect")}>
                        SIGN IN
                        <img src={microsoftIcon} alt="microsoft icon" className='microsoft-icon' />
                    </ColorButton>
                    <Typography className="login-copyright-text">
                        © Copyright VERITAS CAPITAL 2024
                    </Typography>
                </Paper>
            </Box>
        </main>
    );
};

export default Login;
