import React from "react"
import Typography from '@mui/material/Typography';
import Loader from "../../common/Loader";
import "../index.css"
 
const SituationOverview = ({data}) => {
    if (!data || !data.situationOverviewdata) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <Loader top={"50%"}/> {/* MUI CircularProgress component as a loader */}
            </div>
        );
    }
    console.log(data,"rowsrowsrowsrowsrowsrows")
    return (
        <main style={{ marginTop: "18px", marginLeft: "37px", marginRight: "40px",textAlign: "justify" }}>
            {data?.situationOverviewdata?.map((item, key) => (
                <section key={key}>
                    <Typography style={{ fontSize: "14px", fontWeight: "bold", color: "#19213D" }}>
                        {item?.name}:
                    </Typography>
                    <ul style={{ color: "#666F8D", fontSize: "14px" }}>
                        {item.description.map((desc, idx) => (
                            Array.isArray(desc) ? (
                                desc.map((nestedDesc, nestedIdx) => (
                                    <li key={nestedIdx}>{nestedDesc}</li>
                                ))
                            ) : (
                                <li key={idx}>{desc}</li>
                            )
                        ))}
                    </ul>
                </section>
            ))}
        </main>
    )
}
export default SituationOverview