import axios from 'axios';
import { environment } from "../../../environment";


const buildUrl = (path) => {
    return environment.baseUrl + path;
};

const getUserDetails = () => {
    const response = JSON.parse(localStorage.getItem("userLoginInfo"))
    const data = {
        email_id : response.userEmail,
        access_token: response.access_token,
    }
 
    const options = {
        headers: data
    }
    return options
}

const getFinancialFile = async (two_pager_id) => {
    const data = await axios.get(
        buildUrl(`2pager/view_v2/export_excel?two_pager_id=${two_pager_id}`),
        {
            responseType: 'blob', // Important for handling binary data
        },
        null,
        getUserDetails()
    )
    console.log(data);
    return data;
};

export {
    getFinancialFile
};