import React, { useRef, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Grid, Typography,Switch, FormControlLabel, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Header from "../drawer/Header";
import "./DocumentEditPageStyle.css"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RepeatIcon from '@mui/icons-material/Repeat';
import { styled } from '@mui/material/styles';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Modal, Paper } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// import { useDropzone } from 'react-dropzone';

import genrateTwoPagerImage from "../../Assets/Icons/Rich text.svg"
import refreshImage from "../../Assets/Icons/Frame 290.svg"
import sideImage from "../../Assets/Icons/Vector 62.svg"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
// import InputLabel from '@mui/material/InputLabel'; 
import InputBase from '@mui/material/InputBase';
// import CustomStepper from './stepper';
// import Paper from '@mui/material/Paper';
import { StepIconProps } from "@mui/material/StepIcon";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Stack from "@mui/material/Stack";
import Check from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { view2GenerationTrigger, view2GenerationStatus, generationCancel, fetchPopulateData, rePopulateData } from "./service"
import { environment } from "../../environment";
import { useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { updateBTEV } from "../redux/reducer/twoPagerSlice";
import { useDispatch } from "react-redux";
import FileUploadStatus from './FileUploadStatus'
import InfoIcon from "@mui/icons-material/Info";

export default function DocumentEditPage({onExternalToggle}) {
  const urlParams = useParams();
  const params = urlParams.userId
  const location = useLocation();
  const [releaseVersion, setReleaseVersion] = useState("NA");
  const [open, setOpen] = useState(false);
  const [openCanclePopUp, setOpenCanclePopUp] = useState(false);
  const [cardShow, setCardOpen] = useState(false);
  const [cancle, setCancle] = useState('yellow');
  const [dealCloudId, setDealCloudId] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [genrateTwopagerStatus, setGenrateTwopagerStatus] = useState(false);
  const [genrationTrigger, setGenrationTrigger] = useState();
  const [genrationStatus, setGenrationStatus] = useState();
  const [dealName, setDealName] = useState('');
  const [fundType, setFundType] = useState('');
  const [statusApiIntervalId, setStatusApiIntervalId] = useState(null);
  const [hasRun, sethasRun] = useState(false);
  const [dealCloudIdInprogress, setDealCloudIdInprogress] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [reportId, setReportId] = useState('');
  const [oldReportId, setOldReportId] = useState([]);
  const [isExternalData, setIsExternalData] = useState(true);

  const handleExternalData = (event) => {
    const newState = event.target.checked;
    setIsExternalData(newState);
    if (onExternalToggle) onExternalToggle(newState);
  };

  const navigate = useNavigate();
  const buildUrl = (path) => {
    return environment.baseUrl + path;
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseCanclePopUP = () => {
    setOpenCanclePopUp(false)
  }
  const SubmitCloseCanclePopUP = () => {
    setOpenCanclePopUp(false)
    setCancle('red')
  }

  const handleOpenCanclePopUP = () => {
    setOpenCanclePopUp(true)
  }



  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#eaeaf0",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#eaeaf0",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 12,
    alignItems: "center",
    borderRadius: "50%", // Add rounded border
    border: `2px solid ${theme.palette.mode === "dark" ? theme.palette.grey[700] : "white"
      }`, // Border color same as icon color
    boxSizing: "content-box", // Ensure the border is outside the size of the icon
    padding: 3, // Adjust padding as needed
    ...(ownerState && {
      color: cancle,
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "white",
      zIndex: 1,
      fontSize: 25,
      borderRadius: "60%",
      backgroundColor: "green",
    },
    "& .QontoStepIcon-circle": {
      width: 25,
      height: 25,
      borderRadius: "80%",
      zIndex: 1,
      backgroundColor: "currentColor",
    },
  }));

  const [statusData, setStatusData] = useState({
    cim_status: "",
    pager_status: "",
    Uploaded: [],
    Completed: [],
    InProgress: [],
    Cancelled: [],
    Failed: [],
    Queued: [],
    supporting_document_status: ""
  });

  const sections = [
    {
      "Uploaded": [1, 8]
    },
    {
      "Completed": [7

      ]
    },
    {
      "In Progress": [3, 4, 10]
    },
    {
      "Cancelled": [6, 9]
    },
    {
      "Failed": [2, 5]
    }
  ]



  // function QontoStepIcon(props) {
  //   const { active, completed, className } = props;

  //   return (
  //     <QontoStepIconRoot ownerState={active} className={className}>
  //       {completed ? (
  //         <Check className="QontoStepIcon-completedIcon" />
  //       ) : (
  //         <div className="QontoStepIcon-circle" />
  //       )}
  //     </QontoStepIconRoot>
  //   );
  // }

  // toast.configure();

  // const steps = [
  //   "CIM Document Processing",
  //   "Section-Wise Generation",
  //   "Key Information",
  //   "Business Description",
  //   "Investment Opportunity and Thesis",
  //   "Merits and Considerations ",
  //   "Preliminary Investment Thesis ",
  //   "Financial Overview ",
  //   "Ownership /Management ",
  //   "Situation Overview / Next Steps ",
  //   "Past Similar Looks ",
  //   "Public Comps ",
  //   "Other Relevant Information ",
  //   "Finalizing",
  //   "CIM Generation Completed",
  // ];
  const steps = [
    "CIM Document Processing",
    // "Section-Wise Generation",
    "Supporting Document(s)",
    "Key Information",
    "Business Description",
    // "Investment Opportunity and Thesis",
    "Merits and Considerations ",
    "Preliminary Investment Thesis ",
    "Financial Overview ",
    "Ownership /Management ",
    "Situation Overview / Next Steps ",
    "Past Similar Looks ",
    "Public Comps ",
    "Other Relevant Information ",
    // "Finalizing",
    "Two Pager Generation Status",
  ];
  const [selectedFile, setSelectedFile] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [docName, setDocName] = useState('');
  const [newCimIdGenrated, setNewCimIdGenrated] = useState('');
  const [cancelButton, setCancelButton] = useState(false)
  const bottomRef = useRef(null);
  const [uploadButtonStatus, setUploadButtonStatus] = useState(true);
  const [genrationButtonActive, setGenrationButtonActive] = useState(true)
  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(updateBTEV(false))
    setReleaseVersion(JSON.parse(localStorage.getItem("releaseVersion")))
  }, [releaseVersion]);

  useEffect(() => {
    if (cardShow || params) {
      scrollToBottom();
    }
  }, [cardShow, params]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    return () => { clearInterval(statusApiIntervalId) }
    setGenrationButtonActive(true);
  }, [statusApiIntervalId]);


  useEffect(() => {
    if (genrationStatus?.["2pager_status"] === "Completed") {
      clearInterval(statusApiIntervalId)
    }
  }, [genrationStatus]);


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const clearUploadFile = () => {
    document.getElementById('file-upload').value = '';
    setSelectedFile(null)
    setCardOpen(false)
    setGenrateTwopagerStatus(false)
  }


  const handleDashboard = () => {
    navigate("/");
  }
  const getUserDetails = () => {
    const response = JSON.parse(localStorage.getItem("userLoginInfo"))
    const data = {
      email_id: response?.userEmail,
      access_token: response?.access_token,
    }

    const options = {
      headers: data
    }
    return options.headers;
  }

  const handleUpload = async () => {
    try {
      if (!selectedFile) {
        toast.error('Please select a file to upload.', {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }

      const allowedTypes = ['application/pdf']; 
      if (!allowedTypes.includes(selectedFile.type)) {
        toast.warn('Invalid file type. Please upload a PDF file.', {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }
  
      setLoading(true);
      setOpen(false);
      setUploadButtonStatus(false);
      const errorMessage1 = 'File uploading, please wait...';
      const toastId = toast.success(errorMessage1, {
        position: 'top-right',
        autoClose: false,
      });
  
      const formData = new FormData();
      formData.append('uploaded_file', selectedFile);
  
      const userHeaders = getUserDetails();
  
      const response = await axios.post(
        buildUrl(
          `cim_api/cim/upload?company_name=${encodeURIComponent(
            companyName
          )}&deal_cloud_id=${dealCloudId}&document_name=${encodeURIComponent(
            documentName
          )}`
        ),
        formData,
        {
          headers: {
            ...userHeaders, 
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      if (response?.status === 200) {
        toast.dismiss(toastId);
        setNewCimIdGenrated(response?.data.data.cim_id);
        setGenrateTwopagerStatus(true);
        setUploadButtonStatus(true);
        toast.success('File uploaded successfully!', {
          position: 'top-right',
          autoClose: 5000,
        });
      }
    } catch (error) {
      setOpen(false);
      console.error('Error uploading file:', error.response);
  
      const errorMessage =
        error.response?.data?.detail ||
        'Error uploading file. Please try again.';
  
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
      });
    } finally {
      setLoading(false); // Stop the loader
    }
  };
  


  const handleNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const fetchUploadResearch = async (pagerID) => {
    try {
      const response = await rePopulateData(pagerID);
      console.log("hello", pagerID, response, response?.data?.report_data);

      if (response) {
        setGenrateTwopagerStatus(true)
        setNewCimIdGenrated(response?.data?.cim_id === null ? "" : response?.data?.cim_id)
        setDealCloudId(response?.data?.deal_cloud_id === null ? "" : response?.data?.deal_cloud_id)
        setDealName(response?.data?.deal_name === null ? "" : response?.data?.deal_name)
        setFundType(response?.data?.fund_type_specific === null ? "" : response?.data?.fund_type_specific)
        setCompanyName(response?.data?.company_name === null ? "" : response?.data?.company_name)
        setDocName(response?.data?.cim_document_name === null ? "" : response?.data?.cim_document_name)
        setDocumentName(response?.data?.document_name_2pager === null ? "" : response?.data?.document_name_2pager)
        setUploadedFiles(response?.data?.report_data?.length === 0 ? [] : response?.data?.report_data)
        setOldReportId(response?.data?.report_data?.length === 0 ? [] : response?.data?.report_data)
        setIsExternalData(response?.data?.external_source === "True" ? true : false)
        toast.success(response?.data?.message, {
          position: 'top-right',
          autoClose: 5000, // Close the notification after 5 seconds
        });
      }


    } catch (response) {
      toast.error(response?.status, {
        position: 'top-right',
        autoClose: 5000, // Close the notification after 5 seconds
      })
    }
  };

  useEffect(() => {
    if (params) {
      if (location?.state === "UploadResearch") {
        setCardOpen(false)
      }
      else if (location?.state?.status === "Failed" ||  location?.state?.status ===  "Cancelled") {
        setCardOpen(true);
      }
      else {
        setCardOpen(true)
        fetchGenrationStatusInprogress(); // Initial call
        const interval = setInterval(fetchGenrationStatusInprogress, 5000); // Repeat every 5 seconds
        setStatusApiIntervalId(interval)
        return () => clearInterval(interval); // Cleanup on unmount
      }


    }
  }, [params]);

  useEffect(() => {

    if (dealCloudIdInprogress && !hasRun) {
      fetchPopulateDealdata(dealCloudIdInprogress)
      sethasRun(true)
    }

  }, [dealCloudIdInprogress, hasRun])
  useEffect(() => {
    if (location?.state === "UploadResearch") {
      setCardOpen(false)
      fetchUploadResearch(params)
    }
  }, [])
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (params) {
      fetchGenrationStatus()
    }
  };


  const fetchGenrationStatus = async () => {
    try {
      const response = await view2GenerationStatus(params, reportId);
      if (response !== undefined) {
        setGenrationStatus(response?.data);
      }

      setDealCloudId(response?.data.deal_id)
      setDocName(response?.data.cim_document_name)
      setCompanyName(response?.data.company_name)


    } catch (error) {
      console.error("Error fetching generation status:", error);

    }
  };
  const fetchGenrationStatusInprogress = async () => {
    try {
      const response = await view2GenerationStatus(params, reportId);
      if (response !== undefined) {
        setGenrationStatus(response?.data);
      }

      setDealCloudId(response?.data.deal_id)
      setDealCloudIdInprogress(response?.data.deal_id)
      setDocName(location?.state?.doc)
      setDocumentName(response?.data.cim_document_name)
      setCompanyName(response?.data.company_name)


    } catch (error) {
      console.error("Error fetching generation status:", error);

    }
  };

  const messagePopUp = () => {
    const Message = 'Generation is already in progress ';
    toast.success(Message, {
      position: 'top-right',
      autoClose: 5000, // Close the notification after 5 seconds
    });
  }



  const fetchGenrationTrigger = async () => {
    const errorMessage1 = 'Generation will begin shortly';
    const toastId1 = toast.success(errorMessage1, {
      position: 'top-right',
      autoClose: false
    });
    const parameter = {
      root: location?.state === "UploadResearch" ? "True" : "False",
      old_report_id: oldReportId,
      two_pager_id: params,
    }
    try {
      const response = await view2GenerationTrigger(newCimIdGenrated, companyName, documentName, dealCloudId, reportId, parameter, isExternalData);
      // setGenrationTrigger(response?.data);
      setGenrationTrigger(response?.data?.two_pager_id);
      setReportId(response?.data?.report_id)
      if (response?.data?.two_pager_id) {
        const intervalId = setInterval(async () => {
          try {
            const res = await view2GenerationStatus(response?.data.two_pager_id, response?.data?.report_id);
            setGenrationButtonActive(false);
            if (res !== undefined) {
              setGenrationStatus(res?.data);
              if (res.data.cim_status !== "") {
                toast.dismiss(toastId1);

              }
            }
          } catch (statusError) {
            console.error("Error fetching generation status:", statusError);
          }
        }, 5000);
        setStatusApiIntervalId(intervalId)
        // clearInterval(intervalId);
      }
    } catch (error) {
      toast.dismiss(toastId1);
      const toastFailed = toast.error('Generation failed!', {
        position: 'top-right',
        autoClose: false
      });
      toastFailed()
      console.error("Error triggering generation:", error);
    }
  };


  const handleDocumentNameChange = (event) => {
    setDocumentName(event.target.value);
  };

  const genrateCard = () => {
    setCardOpen(true);
    if (!params || location?.state === "UploadResearch") {
      fetchGenrationTrigger()
    }
    else {
      toast.success('Generation is already in progress', {
        position: 'top-right',
        autoClose: 5000, // Close the notification after 5 seconds
      });
    }
  }

  useEffect(() => {
    if (genrationStatus?.sections?.length > 0) {
      const newUploaded = genrationStatus?.sections[0]?.Uploaded?.map(a => a + 1);
      const newCompleted = genrationStatus?.sections[1]?.Completed?.map(a => a + 1);
      const newInProgress = genrationStatus?.sections[2]["In Progress"]?.map(a => a + 1);
      const newCancelled = genrationStatus?.sections[3]?.Cancelled?.map(a => a + 1);
      const newFailed = genrationStatus?.sections[4]?.Failed?.map(a => a + 1);
      const newQueued = genrationStatus?.sections[5]?.Queued?.map(a => a + 1);


      setStatusData((prevState) => ({
        ...prevState,
        cim_status: genrationStatus?.cim_status,
        pager_status: genrationStatus["2pager_status"],
        Uploaded: newUploaded,
        Completed: newCompleted,
        InProgress: newInProgress,
        Cancelled: newCancelled,
        Failed: newFailed,
        Queued: newQueued,
        supporting_document_status: genrationStatus?.supporting_document_status
      }));
    }
  }, [genrationStatus]);

  function QontoStepIcon(props) {
    const { active, completed, className, icon } = props;


    return (
      <QontoStepIconRoot ownerState={active} className={className}>
        {(statusData?.cim_status === "Completed" && icon === 1) || (statusData["pager_status"] === "Completed" && icon === 13)
          || (statusData?.supporting_document_status === "Completed" && icon === 2)
          ? <Check className="QontoStepIcon-completedIcon stepItem" />
          : (statusData?.cim_status === "Uploaded" && icon === 1) || (statusData["pager_status"] === "Uploaded" && icon === 13)
            || (statusData?.supporting_document_status === "Uploaded" && icon === 2)
            ? <div className="QontoStepIcon-circle stepItem" style={{ backgroundColor: 'aqua' }} />
            : (statusData?.cim_status === "In Progress" && icon === 1) || (statusData["pager_status"] === "In Progress" && icon === 13)
              || (statusData?.supporting_document_status === "In Progress" && icon === 2)
              ? <div className="QontoStepIcon-circle stepItem" style={{ backgroundColor: 'yellow' }} />
              : (statusData?.cim_status === "Cancelled" && icon === 1) || (statusData["pager_status"] === "Cancelled" && icon === 13)
                || (statusData?.supporting_document_status === "Cancelled" && icon === 2)
                ? <div className="QontoStepIcon-circle stepItem" style={{ backgroundColor: 'orange' }} />
                : (statusData?.cim_status === "Failed" && icon === 1) || (statusData["pager_status"] === "Failed" && icon === 13)
                  || (statusData?.supporting_document_status === "Failed" && icon === 2)
                  ? <div className="QontoStepIcon-circle stepItem" style={{ backgroundColor: 'red' }} />
                  : (statusData?.cim_status === "Queued" && icon === 1) || (statusData["pager_status"] === "Queued" && icon === 13)
                    || (statusData?.supporting_document_status === "Queued" && icon === 2)
                    ? <div className="QontoStepIcon-circle stepItem" />

                    : statusData?.Uploaded?.includes(icon)
                      ? <div className="QontoStepIcon-circle" style={{ backgroundColor: 'aqua' }} />
                      : statusData?.Completed?.includes(icon)
                        ? <Check className="QontoStepIcon-completedIcon" />
                        : statusData?.InProgress?.includes(icon)
                          ? <div className="QontoStepIcon-circle" style={{ backgroundColor: 'yellow' }} />
                          : statusData?.Cancelled?.includes(icon)
                            ? <div className="QontoStepIcon-circle" style={{ backgroundColor: 'orange' }} />
                            : statusData?.Failed?.includes(icon)
                              ? <div className="QontoStepIcon-circle" style={{ backgroundColor: 'red' }} />
                              : <div className="QontoStepIcon-circle" />
        }
      </QontoStepIconRoot>
    );
  }


  const genrationCancelTrigger = async () => {
    const cancelMSG = 'Cancellation in progress!';
    const toastCancel = toast.success(cancelMSG, {
      position: 'top-right',
      autoClose: false
    });

    try {
      const cidParams = location?.state === "UploadResearch" ? genrationTrigger : params
      setOpenCanclePopUp(false);
      const response = params
        ? await generationCancel(cidParams)
        : await generationCancel(genrationTrigger);

      if (response) {
        toast.dismiss(toastCancel);
        clearInterval(statusApiIntervalId);
        setCancelButton(true);
        let intervalIdStatus = setInterval(function () {
          if (params) {
            const res = view2GenerationStatus(params, reportId);
            if (res !== undefined) {
              setGenrationStatus(res?.data);
            }
          } else {
            const res = view2GenerationStatus(genrationTrigger, reportId);
            if (res !== undefined) {
              setGenrationStatus(res?.data);
            }
          }
        }, 5000);
        setCancle('red');
        setTimeout(function () {
          clearInterval(intervalIdStatus);
        }, 10000);

        const errorMessage = 'Generation of two pager cancelled';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 3000, // Close the notification after 3 seconds
        });
      }
    } catch (error) {
      console.error("Error triggering generation:", error);
      setOpenCanclePopUp(false);
      toast.dismiss(toastCancel);
      const cancelFailedMSG = 'Cancellation failed!';
      const toastCancelFailed = toast.error(cancelFailedMSG, {
        position: 'top-right',
        autoClose: true
      });
    }

  };

  const handleDealCloudIdChange = (event) => {
    setDealCloudId(event.target.value);
    if (event.target.value.length) {
      setDealName('')
      setCompanyName('')
      setFundType('')
      setDocumentName('')
      setSelectedFile(null)
      setGenrateTwopagerStatus(false)
      document.getElementById('file-upload').value = '';
    }
  };
  const getDealIddata = () => {
    fetchPopulateDealdata(dealCloudId)

  }

  const fetchPopulateDealdata = async (id) => {
    try {
      const response = await fetchPopulateData(id);

      if (response) {
        setCompanyName(response?.company_name === null ? "" : response?.company_name)
        setDealName(response?.deal_name === null ? "" : response?.deal_name)
        setFundType(response?.fund_type_specific === null ? "" : response?.fund_type_specific)
      }
      if (!params) {
        toast.success(response?.message, {
          position: 'top-right',
          autoClose: 5000, // Close the notification after 5 seconds
        });
      }

    } catch (response) {
      if (id) {
        toast.error(response?.response?.statusText, {
          position: 'top-right',
          autoClose: 5000, // Close the notification after 5 seconds
        })
      }
      else {
        toast.error("Please provide the Deal Cloud ID.", {
          position: 'top-right',
          autoClose: 5000, // Close the notification after 5 seconds
        })
      }
    }
  };

  const handleViewGenrateFile = () => {
    if (genrationTrigger) {
      navigate(`/view-generate-2pager/${genrationTrigger}`, { state: { doc: companyName } });
    } else {
      navigate(`/view-generate-2pager/${params}`, { state: { doc: companyName } });
    }
  }

  const onDrop = (acceptedFiles) => {
    const pdfFiles = acceptedFiles.filter(file => file.type === 'application/pdf');
    if (pdfFiles.length === 0) {
      alert('Only PDF files are allowed!');
    } else {
      setSelectedFile(pdfFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    onDrop,
    multiple: false
  });

  const handleReportId = (data) => {
    setReportId(data);
  };

  const handleOldReportId = (data) => {
    console.log(data);
    setOldReportId(data);
  };



  return (
    <>
      <Header />
      <Grid sx={{ mx: 2 }}>
        <Grid>
          <Grid md={12} style={{ display: 'flex', justifyContent: 'space-between', padding: '30px 40px 30px 47px' }}>
            <Grid md={8} display='flex' columnGap='10px'>
              <img src={genrateTwoPagerImage} style={{ width: '30px', height: '30px' }} />
              <Typography style={{ fontSize: '22px' }} >
                Generate New Two Pager
              </Typography>
            </Grid>
            <Grid md={2}>
              <Button style={{ color: 'black', background: 'white' }} onClick={() => handleDashboard()}>
                <ArrowBackIosIcon />
                Back
              </Button>
            </Grid>
          </Grid>
          <Divider style={{ background: "#E5E5E5", margin: "30px", height: "1px" }} />


          <Grid md={8} style={{ display: 'flex', padding: '1% 30px', gap: '10px', flexWrap: 'wrap' }}>
            <Grid md={4}>

              <InputLabel htmlFor="outlined-cloud-id" sx={{ fontSize: '12px' }}>Deal Cloud ID</InputLabel>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, "& input::placeholder": {
                    fontSize: "12px", paddingLeft: '10px'
                  }
                }}
              >
                <InputBase
                  // label="With normal TextField"
                  fullWidth
                  id="outlined-cloud-id"
                  placeholder="Enter Deal Cloud ID"
                  // sx={{ size:'10px '}}
                  sx={{ m: 0.5 }}
                  size='small'
                  onChange={handleDealCloudIdChange}
                  value={dealCloudId}

                />
                {!params ?
                  <Button variant="contained" color="primary" component="span" disabled={genrateTwopagerStatus}
                    style={{ backgroundColor: genrateTwopagerStatus ? '#C5C5D6' : '#292F62', fontSize: '12px' }} onClick={() => getDealIddata()} >
                    Get
                  </Button> :
                  <Button variant="contained" color="primary" component="span" style={{ backgroundColor: '#C5C5D6', fontSize: '12px' }} disabled={true} >
                    Get
                  </Button>}
              </Paper>
            </Grid>

            <Grid md={4} style={{ display: 'flex', flexDirection: 'column' }}>
              <InputLabel htmlFor="Deal-Name" sx={{ fontSize: '12px' }}>Deal Name</InputLabel>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, "& input::placeholder": {
                    fontSize: "12px", paddingLeft: '10px'
                  }
                }}
              >
                <InputBase
                  fullWidth
                  id="Deal-Name"
                  placeholder="Enter Deal Name"
                  value={dealName}
                  sx={{ m: 0.5, cursor: "not-allowed" }}
                  size='small'
                  disabled={!dealCloudId}
                  readOnly
                />
              </Paper>
            </Grid>


          </Grid>

          <Grid md={8} style={{ display: 'flex', padding: '1% 30px', gap: '10px', flexWrap: 'wrap' }}>

            <Grid md={4}>

              <InputLabel htmlFor="outlined-Fund-type-specific" sx={{ fontSize: '12px' }}>Fund Type Specific</InputLabel>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, "& input::placeholder": {
                    fontSize: "12px", paddingLeft: '10px'
                  }
                }}
              >
                <InputBase
                  fullWidth
                  id="outlined-Fund-type-specific"
                  placeholder="Enter Fund Type Specific"
                  value={fundType}
                  sx={{ m: 0.5,  cursor: "not-allowed" }}
                  size='small'
                  disabled={!dealCloudId}
                  readOnly
                />
              </Paper>
            </Grid>
            <Grid md={4}>

              <InputLabel htmlFor="outlined-company-Name" sx={{ fontSize: '12px' }}>Company Name</InputLabel>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, "& input::placeholder": {
                    fontSize: "12px", paddingLeft: '10px'
                  }
                }}
              >
                <InputBase
                  fullWidth
                  id="outlined-company-Name"
                  placeholder="Enter Company Name"
                  onChange={handleNameChange}
                  sx={{ m: 0.5,  cursor: "not-allowed"}}
                  size='small'
                  value={companyName}
                  disabled={!dealCloudId}
                  readOnly
                />
              </Paper>
            </Grid>



          </Grid>

          <Grid md={8} style={{ display: 'flex', padding: '1% 30px', gap: '10px', flexWrap: 'wrap' }}>


            <Grid md={4} style={{ display: 'flex', flexDirection: 'column' }}>
              <InputLabel htmlFor="Document-company" sx={{ fontSize: '12px' }}>Document Name</InputLabel>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, "& input::placeholder": {
                    fontSize: "12px", paddingLeft: '10px'
                  }
                }}
              >
                <InputBase
                  // label="With normal TextField"
                  fullWidth
                  id="Document-company"
                  placeholder="Enter Document Name"
                  // sx={{ size:'10px '}}
                  sx={{ m: 0.5 }}
                  size='small'
                  onChange={handleDocumentNameChange}
                  value={documentName}
                  disabled={!dealCloudId}

                />
              </Paper>
            </Grid>


            <Grid md={6} style={{ display: 'flex', gap: '10px' }}>
              <Grid>
                <InputLabel htmlFor="Document-company" sx={{ fontSize: '12px' }}>CIM Document</InputLabel>
                <Paper
                  component="form"
                  sx={{
                    p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, "& input::placeholder": {
                      fontSize: "12px", paddingLeft: '10px'
                    }
                  }}
                >

                  <InputBase
                    // label="With normal TextField"
                    onChange={handleFileChange}

                    value={params ? docName : selectedFile ? selectedFile.name : ""}

                    fullWidth
                    // id="Document-company"
                    placeholder="Enter Document Name"
                    // sx={{ size:'10px '}}
                    sx={{ m: 0.5 }}
                    size='small'
                    disabled={true}
                  // type="file"
                  // id="file-upload"
                  // accept=".pdf"

                  />


                  {documentName && !params && uploadButtonStatus ?
                    <Button variant="contained" color="primary" component="span" style={{ backgroundColor: '#292F62', fontSize: '12px' }} onClick={handleOpen} >
                      upload
                    </Button> :
                    <Button variant="contained" color="primary" component="span" style={{ backgroundColor: '#C5C5D6', fontSize: '12px' }} disabled={true} >
                      upload
                    </Button>}
                  <ToastContainer />
                </Paper>
              </Grid>
              <Grid>
                <input
                  type="file"
                  id="file-upload"
                  accept=".pdf"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                {/* </label> */}
                {selectedFile ?
                  <IconButton aria-label="delete" color="primary">
                    <DeleteOutlineIcon style={{ color: '#FF4E28', marginTop: '15px' }} onClick={() => clearUploadFile()} />
                  </IconButton> : <></>}
              </Grid>
            </Grid>

          </Grid>

          {dealCloudId && <Grid md={8} style={{ display: 'flex', padding: '1% 30px', gap: '10px', flexWrap: 'wrap' }}>
            <Grid md={4} style={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
            <Box display="flex" alignItems="center" gap={1}>
      <InputLabel
        htmlFor="custom-toggle"
        sx={{
          fontSize: "12px",
        }}
      >
        External Data Processing
      </InputLabel>
      <Tooltip title={
         <>
         <Typography sx={{
                fontSize: "12px",
                lineHeight: "1.2",
              }}>
           When turned on, retrieves external data from sources such as Congress.gov.
         </Typography>
         <Typography sx={{
                fontSize: "12px",
                lineHeight: "1.2",
              }}>
           When turned off, generation is faster, using only the CIM and uploaded supporting documents (if any).
         </Typography>
       </>
      }
>
        <InfoIcon
          sx={{
            fontSize: "16px",
            color: "gray",
            cursor: "pointer",
          }}
        />
      </Tooltip>
    </Box>
                  <FormControlLabel
                    control={
                      <Switch
                        id="custom-toggle"
                        checked={isExternalData}
                        onChange={handleExternalData}
                        size="medium"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "rgb(41, 47, 98)", 
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "rgb(41, 47, 98)",
                            },
                          "& .MuiSwitch-track": {
                            backgroundColor: "rgb(41, 47, 98)",
                          },
                          cursor : !genrateTwopagerStatus ? "not-allowed" : "pointer" 
                        }}
                        disabled={!genrateTwopagerStatus}
                      />
                    }
                    label=""
                  />
                 
                </Box>
              <InputLabel htmlFor="Document-company" sx={{ fontSize: '12px' , mt: 1.5}}>Supporting Document(s)</InputLabel>
              <FileUploadStatus
                uploadedFiles={uploadedFiles}
                clickable={genrateTwopagerStatus}
                companyName={encodeURIComponent(companyName)}
                dealCloudId={dealCloudId}
                getReportId={handleReportId}
                documentName={encodeURIComponent(documentName)}
                getOldReportId={handleOldReportId}
              />
            </Grid>
          </Grid>}
          <Modal open={open} onClose={handleClose}>
            <Paper
              sx={{
                position: 'absolute',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Grid container justifyContent="flex-end">
                <HighlightOffIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
              </Grid>
              <Grid container justifyContent="center" alignItems="center" direction="column">
                <InsertDriveFileIcon style={{ color: '#292F62', fontSize: 40 }} />
                <div
                  {...getRootProps()}
                  style={{
                    border: '2px dashed #292F62',
                    padding: '20px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    marginTop: '20px'
                  }}
                >
                  <input {...getInputProps()} />
                  <Typography variant="body1" gutterBottom style={{ fontSize: '13px' }}>
                    Drag and drop a PDF file here or click to select a file.
                  </Typography>
                </div>
                <Typography variant="h6" gutterBottom style={{ fontSize: '13px' }}>
                  or
                </Typography>
                <Grid style={{ marginBottom: '5%' }}>
                  <label htmlFor="file-upload">
                    <Button variant="contained" color="primary" component="span" style={{ backgroundColor: '#292F62', fontSize: '12px' }}>
                      <InsertDriveFileOutlinedIcon />
                      Choose your files
                    </Button>
                  </label>
                </Grid>
                <Grid>
                  {selectedFile && (
                    <Typography variant="h6" gutterBottom style={{ fontSize: '13px' }}>
                      File Name: {selectedFile.name}
                    </Typography>
                  )}
                </Grid>
                {selectedFile && dealCloudId && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: '#292F62', fontSize: '12px' }}
                    onClick={handleUpload}
                  >
                    Upload
                  </Button>
                )}
              </Grid>
            </Paper>
          </Modal>

          {statusData["pager_status"] === "Cancelled" ? (
            <Button variant="contained" style={{ backgroundColor: '#C5C5D6', margin: '2% 30px' }} disabled>
              <InsertDriveFileOutlinedIcon />
              Generate Two Pager
            </Button>
          ) : (
            (genrateTwopagerStatus || params) ? (
              genrationButtonActive ? (
                <>

                  <Button variant="contained" style={{ backgroundColor: '#292F62', margin: '2% 30px' }} onClick={genrateCard}>
                    <InsertDriveFileOutlinedIcon />
                    Generate Two Pager
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="contained" style={{ backgroundColor: '#292F62', margin: '2% 30px' }} onClick={messagePopUp}>
                    <InsertDriveFileOutlinedIcon />
                    Generate Two Pager
                  </Button>
                </>
              )
            ) : (
              <>
                <Button variant="contained" style={{ backgroundColor: '#C5C5D6', margin: '2% 30px' }} disabled>
                  {loading ? 'Uploading file..' : <>
                    <InsertDriveFileOutlinedIcon />
                    Generate Two Pager
                  </>}
                </Button>
              </>
            )
          )}



        </Grid>
        {(cardShow) &&
          <Grid style={{ display: 'flex', justifyContent: 'center' }}>

            <Card
              style={{
                border: "1px solid #FFFFFF",
                boxShadow: "0px 4px 16px rgba(50, 65, 255, 0.16)",
                // padding: "20px 30px",
                borderRadius: "20px 20px 20px 20px",
                width: "97%",
                marginBottom: '10%',
                // height: '400px',
                marginLeft: '17px',
              }}
            >
              <Grid style={{ position: 'relative' }}>
                <img src={sideImage} style={{ width: '72px', height: '73px' }} hidden />
                {activeStep <= 14 ?
                  <img src={refreshImage} style={{ width: '57px', height: '52px', position: 'absolute', left: '10px', top: '10px' }} onClick={handleNext} disabled={activeStep === steps.length - 1} hidden />
                  : <></>}
                <ul className="legends">
                  <li><span className="dot aqua"></span> {"Uploaded"}</li>
                  <li><span className="dot grey"></span> {"Queued"}</li>
                  <li><span className="dot yellow"></span> {"In Progress"}</li>
                  <li><span className="dot green"></span> {"Completed"}</li>
                  <li><span className="dot orange"></span> {"Cancelled"}</li>
                  <li><span className="dot red"></span> {"Failed"}</li>
                </ul>
              </Grid>
              <Grid style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                {(cancelButton || statusData["pager_status"] === "Completed" || statusData["pager_status"] === "Cancelled") ?
                  <></> : <Button variant="contained" style={{ backgroundColor: '#292F62', margin: '2%', fontSize: '12px' }} onClick={handleOpenCanclePopUP}>
                    <InsertDriveFileOutlinedIcon />  cancel
                  </Button>}
              </Grid>

              <Modal open={openCanclePopUp} onClose={handleCloseCanclePopUP}>
                <Paper
                  sx={{
                    position: 'absolute',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <Grid style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <HighlightOffIcon onClick={handleCloseCanclePopUP} />
                  </Grid>
                  <Grid style={{ textAlign: 'center' }}>
                    <Grid>

                      <ErrorRoundedIcon sx={{ color: '#FF4E28' }} />
                    </Grid>

                    <Typography variant="body1" gutterBottom style={{ fontSize: '13px' }}>
                      Are you sure you want to cancel?
                    </Typography>

                    <Button variant="contained" color="primary" style={{ backgroundColor: '#292F62', fontSize: '12px', marginTop: '1%' }} onClick={genrationCancelTrigger} >
                      Yes
                    </Button>
                    <Button variant="contained" color="primary" style={{ backgroundColor: '#292F62', fontSize: '12px', marginTop: '1%', marginLeft: '2%' }} onClick={handleCloseCanclePopUP} >
                      No
                    </Button>

                  </Grid>
                </Paper>
              </Modal>

              <Grid style={{ padding: '20px 30px' }}>

              </Grid>
              <Typography variant="body1" style={{ textAlign: "center", margin: "0 0 40px 0" }}>{"Two Pager Section-Wise Generation Status"}</Typography>

              <Stack sx={{ width: "100%" }} spacing={4}>
                <Stepper alternativeLabel activeStep={''} connector={<QontoConnector />} >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>


              <Grid style={{ display: 'flex', justifyContent: 'end', marginRight: '2%' }}>
                {statusData["pager_status"] === "Completed" ?

                  <Button variant="contained" style={{ backgroundColor: '#292F62', margin: '40px 0 25px' }} onClick={() => handleViewGenrateFile()}>
                    <InsertDriveFileOutlinedIcon />
                    VIEW GENERATED TWO PAGER</Button>
                  : <></>}
              </Grid>
            </Card>
          </Grid>}


        <div ref={bottomRef}></div>
        <Typography style={{ padding: 30, fontSize: 14, textAlign: "center" }}>
          &#169; All Rights Reserved (2024) Veritas Capital : Release Version :&nbsp;
          <Typography component='span' style={{ cursor: "pointer", color: "#33348e", fontSize: 14 }} onClick={() => {
            window.open(`${window.location.origin}/release_notes`, '_blank')
          }}>
            {releaseVersion}
          </Typography>
        </Typography>
      </Grid>
    </>

  )
}