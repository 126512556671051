import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { environment } from "../../environment";
import {
  Delete as DeleteIcon,
  CheckCircle as SuccessIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { IconButton, Box } from "@mui/material";
import "./index.css";
import { deleteUplodedFiles } from "./service";

// Utility function to format file sizes in KB, MB, GB
function formatFileSize(sizeInBytes) {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let index = 0;
  let size = sizeInBytes;
  while (size >= 1024 && index < units.length - 1) {
    size /= 1024;
    index++;
  }
  return `${size.toFixed(2)} ${units[index]}`;
}

function FileUploadStatus(props) {
  const [files, setFiles] = useState([]); // Holds the list of all selected files
  const [progress, setProgress] = useState({}); // Keep track of upload progress for each file
  const [uploadStatus, setUploadStatus] = useState({}); // Track success/failure per file
  const [fileSizes, setFileSizes] = useState({}); // Track file sizes
  const [uploadedSizes, setUploadedSizes] = useState({}); // Track uploaded sizes
  const [alreadyUploaded, setAlreadyUploaded] = useState([]); // Track already uploaded files
  const [existingFiles, setExistingFiles] = useState(props?.uploadedFiles); // Track already uploaded files
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const buildUrl = (path) => {
    return environment.baseUrl + path;
  };
  const getUserDetails = () => {
    const response = JSON.parse(localStorage?.getItem("userLoginInfo"));
    const data = {
      email_id: response?.userEmail,
      access_token: response?.access_token,
    };

    const options = {
      headers: data,
    };
    return options?.headers;
  };

  const handleFileChange = (e) => {
    const newFiles = e.target.files;
    let tempSizes = {};
    let tempFiles = [...files];
  

    const allowedTypes = ["application/pdf"];
  
    // Loop through selected files and append them to the existing list
    [...newFiles].forEach((file) => {

      if (allowedTypes.includes(file?.type) && !tempFiles.find((f) => f?.name === file?.name)) {
        tempFiles.push(file);
        tempSizes[file?.name] = file?.size; // Store size in bytes
      } else if (!allowedTypes.includes(file?.type)) {
        toast.warn("Invalid file type. Please upload a PDF file.");
      }
    });
  
    // Update state with new file list and sizes
    setFiles(tempFiles);
    setFileSizes((prevSizes) => ({ ...prevSizes, ...tempSizes }));
  
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  
  // Handle the file upload
  const handleUpload = async () => {
   
    const tempProgress = { ...progress };
    const tempUploadedSizes = { ...uploadedSizes };

    const filesToUpload = files.filter(
      (file) => !alreadyUploaded.includes(file?.name)
    );
    const formData = new FormData();

    // Append all files to the FormData
    filesToUpload.forEach((file) => {
      formData.append("additional_files", file);
    });

    const config = {
      onUploadProgress: (progressEvent) => {
        const total = progressEvent.total;
        const loaded = progressEvent.loaded;
        const percentCompleted = Math.round((loaded * 100) / total);

        // Update progress for the combined upload
        setProgress({ combined: percentCompleted });

        // Update uploaded sizes for all files being uploaded
        tempUploadedSizes.total = loaded;
        setUploadedSizes(tempUploadedSizes);
      },
    };

    try {
      setIsLoading(true);
      const userHeaders = getUserDetails();
      const response = await axios.post(
        buildUrl(
          `cim_api/cim/upload_multiple?company_name=${props?.companyName}&deal_cloud_id=${props?.dealCloudId}&document_name=${props?.documentName}`
        ),
        formData,
        {
          headers: {
            ...userHeaders, // Spread the user headers here
            "Content-Type": "multipart/form-data",
          },
        }
      );
      filesToUpload.forEach((file) => {
        setUploadStatus((prev) => ({ ...prev, [file?.name]: "success" }));
        setAlreadyUploaded((prev) => [...prev, file?.name]); // Mark as uploaded
      });
      props?.getReportId(response?.data?.data?.report_id);
      if (response?.data?.status !== "success") {
        throw new Error("Network response was not ok");
      }
      if (response?.data?.status === "success"){
        setIsUploading(true);
      }
      toast.success("File(s) uploaded successfully!");
    } catch (error) {
      filesToUpload.forEach((file) => {
        setUploadStatus((prev) => ({ ...prev, [file?.name]: "error" }));
      });
      console.error("Error uploading files:", error);
      toast.error(`Upload failed: ${error.message}`);
    }
    finally {
      setIsLoading(false);
    }
  };

  // Handle the remove file request
  const handleRemove = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));

    setUploadStatus((prevStatus) => {
      const newStatus = { ...prevStatus };
      delete newStatus[fileName];
      return newStatus;
    });

    setProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[fileName];
      return newProgress;
    });

    setFileSizes((prevSizes) => {
      const newSizes = { ...prevSizes };
      delete newSizes[fileName];
      return newSizes;
    });

    setUploadedSizes((prevUploaded) => {
      const newUploaded = { ...prevUploaded };
      delete newUploaded[fileName];
      return newUploaded;
    });

    setAlreadyUploaded((prevUploaded) =>
      prevUploaded.filter((file) => file !== fileName)
    );

    // Clear the file input only if the deleted file was the last one
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const handleUplodedRemove = async (file) => {
    try {
      const response = await deleteUplodedFiles({
        report_data: existingFiles,
        report_id: file?.report_id,
      });
      if (response) {
        setExistingFiles(response?.data?.report_data)
        props?.getOldReportId(response?.data?.report_data);
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000, // Close the notification after 5 seconds
        });
      }
      // Clear the file input only if the deleted file was the last one
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    } catch (response) {
      // toast.error(response?.status, {
      //   position: 'top-right',
      //   autoClose: 5000,
      // })
    }
  };

  useEffect(() => {
    setExistingFiles(props?.uploadedFiles)
  }, []);

  return (
    <>
      <div
        style={{
          border: files?.length > 0 ? "1px solid #ddd" : 0,
          padding: files?.length > 0 ? 10 : "10px 0",
          margin: files?.length > 0 ? "4px auto" : 0,
          borderRadius: 10,
        }}
      >
        <div className="customFileInput">
          <input
            type="file"
            id="files-upload"
            multiple
            onChange={(e) => handleFileChange(e)}
            className="fileInput"
            disabled={!props?.clickable || isUploading}
            accept=".pdf"
            ref={fileInputRef}
          />
          <label
            htmlFor="files-upload"
            className="fileInputLabel"
            style={{
              cursor:
                !props?.clickable || isUploading ? "not-allowed" : "pointer",
              backgroundColor:
                !props?.clickable || isUploading
                  ? "rgb(197, 197, 214)"
                  : "rgb(41, 47, 98)",
              border: 0,
              color:
                !props?.clickable || isUploading
                  ? "rgba(0, 0, 0, 0.26)"
                  : "rgb(255, 255, 255)",
            }}
          >
            Select File(s)
          </label>
        </div>
        <ul className="fileList">
          {existingFiles?.length > 0 &&
            [...existingFiles]?.map((file, index) => (
              <li key={index} className="fileItem">
                <div className="statusMark">
                  <SuccessIcon color="success" />
                </div>
                <div className="fileName" title={file?.report_doc_name}>
                  {file?.report_doc_name}
                </div>
                <div className="uploadedSize">{"-"}</div>
                <div
                  className="deleteSection"
                  style={{
                    cursor: isUploading ? "not-allowed" : "pointer",
                  }}
                >
                  <IconButton
                    onClick={() => handleUplodedRemove(file)}
                    className="deleteButton"
                    disabled={isUploading}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </div>
              </li>
            ))}
        </ul>
        <ul className="fileList">
          {files?.length > 0 &&
            [...files]?.map((file) => (
              <li key={file?.name} className="fileItem">
                <div className="statusMark">
                  {uploadStatus[file?.name] === "success" && (
                    <SuccessIcon color="success" />
                  )}
                  {uploadStatus[file?.name] === "error" && (
                    <ErrorIcon color="error" />
                  )}
                </div>
                <div className="fileName" title={file?.name}>
                  {file?.name}
                </div>
                <div className="uploadedSize">
                  {/* {formatFileSize(uploadedSizes[file?.name] || 0)} /{" "} */}
                  {formatFileSize(fileSizes[file?.name])}
                </div>
                {/* <div className="progressBarWrapper">
                <CircularProgressbar
                  value={progress[file?.name] || 0}
                  text={`${progress[file?.name] || 0}%`}
                  styles={buildStyles({
                    pathColor: "#1e90ff",
                    textColor: "#000",
                    trailColor: "#ddd",
                    textSize: "24px",
                  })}
                />
              </div> */}

                <div
                  className="deleteSection"
                  style={{
                    cursor: isUploading ? "not-allowed" : "pointer",
                  }}
                >
                  <IconButton
                    onClick={() => handleRemove(file?.name)}
                    className="deleteButton"
                    disabled={isUploading}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </div>
              </li>
            ))}
        </ul>
        {files.length > 0 && (
          <button
            onClick={handleUpload}
            className="uploadButton"
            style={{
              backgroundColor: isUploading
                ? "rgb(197, 197, 214)"
                : "rgb(41, 47, 98)",
              color: isUploading ? "rgba(0, 0, 0, 0.26)" : "#fff",
              cursor: isUploading ? "not-allowed" : "pointer",
            }}
            disabled={isUploading}
          >
            Upload Files
          </button>
        )}
      </div>
      {files?.length > 0 && (
        <p
          style={{
            margin: 0,
            fontSize: 12,
            color: "#E65100",
          }}
        >
          {"Click on Upload Files button to submit research document(s)."}
        </p>
      )}
        {isLoading && (
            <Box
              className="loaderPage"
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 2000,
              }}
            >
              <RotateRightIcon
                color="inherit"
                fontSize="large"
                sx={{ animation: "spin 2s infinite ease-in-out" }}
              />
            </Box>
          )}
    </>
  );
}

export default FileUploadStatus;
