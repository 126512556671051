import React, { useEffect, useState } from "react";
import { getReleaseDetails } from "./service";
import ReactMarkdown from "react-markdown";
import MarkdownIt from "markdown-it";
import Header from "../drawer/Header";
import { Box } from "@mui/material";

const md = new MarkdownIt();
const ReleaseNotes = () => {
  const [releaseDetails, setReleaseDetails] = useState("");
  const [dataStatus, setDataStatus] = useState("Loading...");
  useEffect(() => {
    const fetchReleaseDetails = async () => {
      try {
        const response = await getReleaseDetails();
        setDataStatus(undefined)
        setReleaseDetails(response?.data?.release_notes);
      } catch (error) {
        setDataStatus("No Data Found!")
        console.log("error:", error);
      }
    };

    fetchReleaseDetails();
  }, []);

  return (
    <>
      <Header />
      <Box component={'div'} style={{ padding: "20px 50px" }}>
        {dataStatus !== undefined ?
          <ReactMarkdown children={dataStatus} components={md} />
          : <ReactMarkdown children={releaseDetails} components={md} />}
      </Box>
    </>
  );
};

export default ReleaseNotes;