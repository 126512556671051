import React from "react"
import SVGIcon from "../../../Assets/Icons/download-financial-csv-icon.svg"
import { getFinancialFile } from "./service"

const FinancialOverview = ({ param, fileName }) => {
    const downloadFinancialFile = async () => {
        try {
            const response = await getFinancialFile(param);
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // let cim_id=String(jsonData).split("_");
            // Create an anchor element and trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();

        } catch (error) {
            console.error("Error fetching generation status:", error);

        }
    };
    return (
        <main className="financial-overview-container">
            <img src={SVGIcon} onClick={() => downloadFinancialFile()} />
        </main>
    )
}

export default FinancialOverview