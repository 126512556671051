import React from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import "../index.css"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F0F2F5",
        color: "#666F8D",
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: "#666F8D"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: "#f7f8f8",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const PastSimilarLooks = ({ rows }) => {
    if (!rows || rows.length === 0) {
        return (
            <main className="other-relavent-container" style={{
                fontSize: "14px",
                color: "#666F8D",
                textAlign: "center",
                padding: "20px"
            }}>
                No relevant information available
            </main>
        );
    }

    return (
        <main>
             <div className="table-container">
        <table className="fixed-table">
          <thead>
            <tr>
              {Object.keys(rows[0]).map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((item, index) =>
                  typeof item === "string" && item?.includes("http") ? (
                    <td key={index}>
                      <a href={item} target="_blank">
                        {item}
                      </a>
                    </td>
                  ) : (
                    <td key={index}>{item}</td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
            {/* <TableContainer component={Paper} className="flipped">
                <Table aria-label="customized table" sx={{ minWidth: 650 }} className="content">
                    <TableHead>
                        <TableRow>
                            {Object.keys(rows[0]).map((item, index) => (
                                <StyledTableCell key={index}>{item}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <StyledTableRow key={index}>
                                {Object.values(row).map((item, index) => (
                                    typeof (item) === "string" && item?.includes("http")
                                        ? <StyledTableCell key={index}><a href={item} target="_blank">{item}</a></StyledTableCell>
                                        : <StyledTableCell key={index}>{item}</StyledTableCell>
                                ))}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> */}
        </main>
    );
};

export default PastSimilarLooks;