import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import "./table.css";
import FolderOpenTwoToneIcon from "@mui/icons-material/FolderOpenTwoTone";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Accordian from "./Accordian";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TablePagination from "@mui/material/TablePagination";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import { getDashboardLists, getDashboard2PagerViewLists } from "./service";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDashboardTableData,
  updateFilterResetState,
} from "../redux/reducer/dashboardSlice";
import Loader from "../common/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoleAssignment from "./RoleAssignment";

const FilterTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expandedRow, setExpandedRow] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [sortingTableIconStyles, setSortingTableIconStyles] = useState({
    docUploaded: "asc",
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [accordianData, setAccordianData] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exportDetails, setExportDetails] = useState([]);
  const dashboardFilter = useSelector((state) => state.dashboard.filterOptions);
  const dashboardSearch = useSelector(
    (state) => state.dashboard.dashboardSearch
  );
  const filterDashboardState = useSelector(
    (state) => state.dashboard.filterState
  );
  const savedDashboardTableLists = useSelector(
    (state) => state.dashboard.dashboardTableData
  );
  const [tableDataStatus, setTableDataStatus] = useState("Loading...");

  useEffect(() => {
    fetchDashboardTableLists();
    setRowsPerPage(parseInt(10, 10));
  }, []);

  useEffect(() => {
    filterData();
  }, [dashboardSearch, dashboardFilter]);

  useEffect(() => {
    if (filterDashboardState.isReset) {
      setTableData(savedDashboardTableLists.data);
      setHistoryData(savedDashboardTableLists.data);
      dispatch(updateFilterResetState({ isReset: false }));
    }
  }, [filterDashboardState]);

  function convertToDayMonthYear(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const filterData = () => {
    let filteredData = tableData;

    if (dashboardFilter.status) {
      filteredData = filteredData.filter(
        (row) =>
          row.status.toLowerCase() === dashboardFilter.status.toLowerCase()
      );
    }
    if (dashboardSearch.searchInput || dashboardFilter.searchInput) {
      filteredData = filteredData.filter((row) => {
        if (dashboardSearch.searchInput) {
          return row.docName
            .toLowerCase()
            .includes(dashboardSearch.searchInput.toLowerCase());
        }
        return row.docName
          .toLowerCase()
          .includes(dashboardFilter.searchInput.toLowerCase());
      });
    }

    // Filter by date range
    if (dashboardFilter.selectedStartDate && dashboardFilter.selectedEndDate) {
      const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split("-");
        return new Date(year, month - 1, day);
      };

      const startDate = parseDate(
        convertToDayMonthYear(dashboardFilter.selectedStartDate)
      );
      const endDate = parseDate(
        convertToDayMonthYear(dashboardFilter.selectedEndDate)
      );

      filteredData = filteredData.filter((row) => {
        const dateOfDocUpload = parseDate(row.dateOfDocUpload);
        return dateOfDocUpload >= startDate && dateOfDocUpload <= endDate;
      });
    }
    setHistoryData(filteredData);

    if (tableData?.length > 0 && filteredData?.length === 0) {
      setTableDataStatus("No Data Found.");
    } else {
      setTableDataStatus("Loading...");
    }
  };

  function convertTimestamp(timestamp) {
    const [datePart] = timestamp.split("_"); // Extract the date part
    const [year, month, day] = datePart.split("-"); // Split the date part into year, month, day
    return `${day}-${month}-${year}`; // Reformat the date
  }

  function extractUsername(email) {
    // Check if the email is "None"
    if (email.toLowerCase() === "none") {
      return "None";
    }

    // Split the email at the '@' character and return the first part
    const [username] = email.split("@");
    return username;
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const fetchDashboardTableLists = async () => {
    const response = await getDashboardLists();
    const arrayOfObjects = Object.keys(response.data.timestamp).map(
      (key, index) => {
        return {
          dateOfDocUpload: convertTimestamp(response.data.timestamp[key]),
          createdBy: extractUsername(response.data.email_id[key]),
          companyName: capitalizeFirstLetter(response.data.company_name[key]),
          cim_id: response.data.cim_id[key],
          id: response.data.cim_id_display[key],
          docName: response.data.document_name[key],
          status: response.data.status[key],
          dateModified: convertTimestamp(response.data.timestamp_modified[key]),
          details: ":",
          access:  response?.data.hasOwnProperty("owner_access")?  response?.data?.owner_access[key] : "no",
        };
      }
    );
    setTableData(arrayOfObjects);
    setHistoryData(arrayOfObjects);
    const data = { data: arrayOfObjects };
    dispatch(updateDashboardTableData({ ...data }));
    if (
      response &&
      response?.status === "success" &&
      arrayOfObjects?.length === 0
    ) {
      setTableDataStatus("No Data Found.");
    }
  };

  const convertTimestampToDayTime = (timestamp) => {
    const [datePart, timePart] = timestamp.split("_");
    const date = new Date(`${datePart}T${timePart}`);
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = dayNames[date.getUTCDay()];
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${dayName} ${hours}:${formattedMinutes}${ampm}`;
  };

  const handleAccordionChange = async (rowId, cim_id, companyName, docName) => {
    setOpenAccordion(false);
    setLoading(true);
    let data = [];
    let exportData = [];
    setExpandedRow(expandedRow === rowId ? null : rowId);
    const response = await getDashboard2PagerViewLists(cim_id);
    if (response) {
      setLoading(false);
      setOpenAccordion(true);
      const version = Object.keys(response.data.version);
      if (version.length) {
        data = version.map((key) => {
          return {
            version: response.data.version[key],
            two_pager_id_display: response.data["2_pager_id_display"][key],
            two_pager_id: response.data["2_pager_id"][key],
            timestamp: response.data.timestamp[key],
            timeModified: response.data.timestamp_modified[key],
            companyName,
            docName: response.data.document_name[key],
            createdBy: extractUsername(response.data.email_id[key]),
            status: response.data.status[key],
            cim_id: response.data.cim_id[key],
            // email_id: response.data.email_id[key],
            generatedBy: response?.data?.hasOwnProperty("name") ? response?.data?.name[key] : undefined,
            modifiedBy: response?.data?.hasOwnProperty("modified_by_name") ? response?.data?.modified_by_name[key] : undefined,
          };
        });

        exportData = version.map((key) => {
          return {
            "Company Name": companyName,
            "Document Name": docName,
            "Created By": extractUsername(response.data.email_id[key]),
            Version: response.data.version[key],
            "2 Pager Id": response.data["2_pager_id_display"][key],
            Timestamp: convertTimestampToDayTime(response.data.timestamp[key]),
            Status: response.data.status[key],
          };
        });
      }
      setAccordianData(data);
      setExportDetails(exportData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setExpandedRow(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleView2PagerDoc = () => {
    navigate("/view-generate-2pager");
  };

  const handleSort = (key, name, propertyName) => {
    setSortingTableIconStyles({ [name]: key });
    if (propertyName === "createdBy") {
      let sortedNamesData = [];
      if (key === "asc") {
        sortedNamesData = historyData
          .slice()
          .sort((a, b) => a.createdBy.localeCompare(b.createdBy));
      } else {
        sortedNamesData = historyData
          .slice()
          .sort((a, b) => b.createdBy.localeCompare(a.createdBy));
      }
      setHistoryData(sortedNamesData);
      if (sortedNamesData?.length === 0) {
        setTableDataStatus("No Data Found.");
      }
      return;
    }

    const sortedData = historyData.slice().sort((a, b) => {
      const dateA = new Date(a[propertyName].split("-").reverse().join("-"));
      const dateB = new Date(b[propertyName].split("-").reverse().join("-"));
      if (key === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    setHistoryData(sortedData);
    if (sortedData?.length === 0) {
      setTableDataStatus("No Data Found.");
    }
  };

  const getStatusColor = (value, bool) => {
    if (bool === true) {
      return "#fff";
    }
    if (value === "Completed") {
      return "#26B144";
    }
    if (value === "In Progress") {
      return "#DD9A19";
    }
    if (value === "Failed") {
      return "#FF4E28";
    }
    return "";
  };

  const dataWithRowID = historyData.map((item, index) => ({
    ...item,
    itemID: index + 1,
  }));
  const displayedData = dataWithRowID.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const formatDate = (timestamp) => {
    const [datePart] = timestamp.split('_');
    const [day, month, year] = datePart.split('-');
    return `${month}-${day}-${year}`;
  };

  const [openRoleAssignment, setOpenRoleAssignment] = useState(false);
  const [dialogCID, setDialogCID] = useState("");
  const handleOpenRoleAssignment = (arg) => {
    setDialogCID(arg);
    setOpenRoleAssignment(true);
  };
  const handleCloseRoleAssignment = () => {
    setDialogCID("");
    setOpenRoleAssignment(false);
  };

  return (
    <Paper style={{ padding: "20px 10px 20px 10px", margin: "50px 50px 0" }}>
      {historyData.length === 0 ? (
        <Typography>{tableDataStatus}</Typography>
      ) : (
        <>
          <section className="table-container" id="sort-table-data">
            {/* Table header */}
            <article className="header"></article>
            <article className="header">CIM Processing Status</article>
            <article className="header">Company Name</article>
            <article className="header">CIM Document Name</article>
            <article className="header">ID</article>
            <article className="header" style={{ position: "relative" }}>
              CIM Uploaded
              {/* {sortBy && (
            <span className={`sort-icon ${sortBy}`} onClick={handleSort}></span>
          )} */}
              <span
                className={`sort-table-doc-asc ${sortingTableIconStyles["docUploaded"] === "asc"
                  ? "sort-table-doc-asc-active"
                  : ""
                  }`}
                onClick={() =>
                  handleSort("asc", "docUploaded", "dateOfDocUpload")
                }
              ></span>
              <span
                className={`sort-table-doc-desc ${sortingTableIconStyles["docUploaded"] === "desc"
                  ? "sort-table-doc-desc-active"
                  : ""
                  } `}
                onClick={() =>
                  handleSort("desc", "docUploaded", "dateOfDocUpload")
                }
              ></span>
            </article>
            <article
              className="header sort-table-date-modify"
              style={{ position: "relative" }}
            >
              Last Modified
              <span
                className={`sort-table-doc-asc ${sortingTableIconStyles["dateModified"] === "asc"
                  ? "sort-table-date-modified-asc-active"
                  : ""
                  }`}
                onClick={() =>
                  handleSort("asc", "dateModified", "dateModified")
                }
              ></span>
              <span
                className={`sort-table-doc-desc ${sortingTableIconStyles["dateModified"] === "desc"
                  ? "sort-table-date-modified-desc-active"
                  : ""
                  } `}
                onClick={() =>
                  handleSort("desc", "dateModified", "dateModified")
                }
              ></span>
            </article>
            <article
              className="header sort-table-createdBy"
              style={{ position: "relative" }}
            >
              Created By
              <span
                className={`sort-table-doc-asc ${sortingTableIconStyles["createdBy"] === "asc"
                  ? "sort-table-created-by-asc-active"
                  : ""
                  }`}
                onClick={() => handleSort("asc", "createdBy", "createdBy")}
              ></span>
              <span
                className={`sort-table-doc-desc ${sortingTableIconStyles["createdBy"] === "desc"
                  ? "sort-table-created-by-desc-active"
                  : ""
                  } `}
                onClick={() => handleSort("desc", "createdBy", "createdBy")}
              ></span>
            </article>

            <article className="header">Assign Roles </article>

            {/* Table body */}
            {displayedData.map((row, index) => (
              <React.Fragment key={row.num}>
                <article
                  display="flex"
                  alignItems="center"
                  style={{ marginTop: "6px" }}
                >
                  <FolderOpenTwoToneIcon
                    style={{ fontSize: 40, color: "#292F62" }}
                  />
                  <span className="row-id">{row.itemID}</span>
                </article>
                <div
                  className={`table-row ${expandedRow === index ? "expanded sec-col" : "sec-col"
                    }`}
                  style={{
                    color: `${getStatusColor(row.status)}`, cursor: "pointer"
                  }}
                  onClick={() =>
                    handleAccordionChange(
                      index,
                      row.cim_id,
                      row.companyName,
                      row.docName
                    )
                  }
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 10,
                      color: `${getStatusColor(
                        row.status,
                        expandedRow === index
                      )}`,
                      marginRight: "3px",
                    }}
                  />
                  {row.status}
                </div>
                <div
                  className={`table-row ${expandedRow === index ? "expanded" : ""
                    }`}
                  onClick={() =>
                    handleAccordionChange(
                      index,
                      row.cim_id,
                      row.companyName,
                      row.docName
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {row.companyName}
                </div>
                <div
                  className={`table-row ${expandedRow === index ? "expanded" : ""
                    }`}
                  onClick={() =>
                    handleAccordionChange(
                      index,
                      row.cim_id,
                      row.companyName,
                      row.docName
                    )
                  }
                  style={{
                    cursor: "pointer", whiteSpace: "nowrap",
                    overflow: "hidden", textOverflow: "ellipsis"
                  }}
                >
                  {row.docName}
                </div>
                <div
                  className={`table-row ${expandedRow === index ? "expanded" : ""
                    }`}
                  onClick={() =>
                    handleAccordionChange(
                      index,
                      row.cim_id,
                      row.companyName,
                      row.docName
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {row.id}
                </div>
                <div
                  className={`table-row ${expandedRow === index ? "expanded" : ""
                    }`}
                  onClick={() =>
                    handleAccordionChange(
                      index,
                      row.cim_id,
                      row.companyName,
                      row.docName
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {formatDate(row?.dateOfDocUpload)}
                </div>
                <div
                  className={`table-row ${expandedRow === index ? "expanded" : ""
                    }`}
                  onClick={() =>
                    handleAccordionChange(
                      index,
                      row.cim_id,
                      row.companyName,
                      row.docName
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {formatDate(row?.dateModified)}
                </div>
                <div
                  className={`table-row ${expandedRow === index ? "expanded" : ""
                    }`}
                  onClick={() =>
                    handleAccordionChange(
                      index,
                      row.cim_id,
                      row.companyName,
                      row.docName
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {row.createdBy}
                </div>
                {console.log(row?.access === "no", row?.access)}
                <div
                  className={`table-row ${
                    expandedRow === index ? "expanded last-col" : "last-col"
                  }`}
                  
                  style={{
                    pointerEvents: row?.access === "no" ? "none" : "auto",
                    cursor: row?.access === "no" ? "not-allowed" : "pointer",
                    padding: "12px 0 0",
                    textAlign: "center",
                  }}
                >
                  <PeopleAltIcon
                    style={{
                      fontSize: 26,
                      color: expandedRow === index ? "fff" : "#292f62",
                      opacity: row?.access === "no" ? 0.5 : 1,
                    }}
                    onClick={() => handleOpenRoleAssignment(row.cim_id)}
                  />
                </div>
                <section
                  className="accordion"
                  style={{ display: expandedRow === index ? "block" : "none" }}
                >
                  {openAccordion ? (
                    <Accordian
                      accordianData={accordianData}
                      jsonData={exportDetails}
                    />
                  ) : (
                    <Loader top={"50%"} />
                  )}
                </section>
              </React.Fragment>
            ))}
          </section>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={historyData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
       <ToastContainer />
       {openRoleAssignment && <RoleAssignment
        open={openRoleAssignment}
        cimID={dialogCID}
        handleOpen={handleOpenRoleAssignment}
        handleClose={handleCloseRoleAssignment}
      />}
    </Paper>
  );
};

export default FilterTable;
