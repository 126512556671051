import React, { useState } from "react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateORI } from "../../redux/reducer/twoPagerSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OtherRelevantInfoEdit = (props) => {
  const dispatch = useDispatch();
  const [editedData, setEditedData] = useState(props?.data);

  const handleResultEdit = (
    newValue,
    itemIndex,
    valuesIndex,
    isSubBullet,
    subBulletIndex
  ) => {
    const updatedData = [...editedData];
    if (isSubBullet) {
      updatedData[itemIndex].sub_bullets[subBulletIndex].values[
        valuesIndex
      ].result = newValue;
    } else {
      updatedData[itemIndex].values[valuesIndex].result = newValue;
    }
    setEditedData(updatedData);
  };

  if (!editedData) {
    return <div>Loading...</div>;
  }

  const handleApply = () => {
    dispatch(updateORI(editedData));
    toast.success(
      "Changes applied. Click the 'Accept' button to confirm and save the changes.",
      {
        position: "top-right",
        autoClose: 10000,
      }
    );
  };
  return (
    <>
      {editedData?.map((item, itemIndex) => (
        <div key={itemIndex} className="editArea">
          <h5>{item?.name}</h5>
          {item?.values &&
            item?.values?.map((value, valuesIndex) => (
              <div key={valuesIndex} className="editChildBullets">
                <textarea
                  rows={5}
                  value={value?.result}
                  onChange={(e) =>
                    handleResultEdit(
                      e?.target?.value,
                      itemIndex,
                      valuesIndex,
                      false,
                      null
                    )
                  }
                />
              </div>
            ))}
          {item?.sub_bullets &&
            item?.sub_bullets?.map((subBullet, subBulletIndex) => (
              <div key={subBulletIndex} className="editChildBullets">
                <h6>{subBullet?.name}</h6>
                {subBullet?.values &&
                  subBullet?.values?.map((value, valuesIndex) => (
                    <div key={valuesIndex} className="editChildBullets">
                      <textarea
                        rows={5}
                        value={value?.result}
                        onChange={(e) =>
                          handleResultEdit(
                            e?.target?.value,
                            itemIndex,
                            valuesIndex,
                            true,
                            subBulletIndex
                          )
                        }
                      />
                    </div>
                  ))}
              </div>
            ))}
        </div>
      ))}

      <Button
        variant="contained"
        style={{
          textTransform: "none",
          height: "30px",
          margin: "15px 15px 5px",
          float: "right",
          backgroundColor: "#7AD167",
          color: "#ffffff",
          position: "fixed",
          bottom: "4%",
          right: "3%"
        }}
        onClick={handleApply}
      >
        Apply
      </Button>
      <ToastContainer />
    </>
  );
};

export default OtherRelevantInfoEdit;
