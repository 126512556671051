import axios from 'axios';
import { environment } from "../../environment";


const buildUrl = (path) => {
    return environment.baseUrl + path;
};

const getUserDetails = () => {
    const response = JSON.parse(localStorage.getItem("userLoginInfo"))
    const data = {
        email_id : response.userEmail,
        access_token: response.access_token,
    }
 
    const options = {
        headers: data
    }
    return options
}


const getReleaseDetails = async () => {
    const userHeaders = getUserDetails()
    const { data } = await axios.get(
        buildUrl(`/platform/release_notes`),
        null,  
        userHeaders
    )
    return data;
}

export {
    getReleaseDetails
};