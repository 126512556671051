import axios from 'axios';
import { environment } from "../../environment";


const buildUrl = (path) => {
    return environment.baseUrl + path;
};

const getUserDetails = () => {
    const response = JSON.parse(localStorage.getItem("userLoginInfo"))
    const data = {
        email_id : response.userEmail,
        access_token: response.access_token,
    }
 
    const options = {
        headers: data
    }
    return options
}


const view2PagerFinalLists = async (cim_id) => {
    const userHeaders = getUserDetails()
    const { data } = await axios.post(
        buildUrl(`2pager/view_v2/get_all_section?two_pager_id=${cim_id}&output_type=json`),
        null,  
        userHeaders
    )
    return data;
}

const getFile = async (two_pager_id) => {
    const data = await axios.get(
        buildUrl(`2pager/view_v2/export_2pager_doc?two_pager_id=${two_pager_id}`),
        {
            responseType: 'blob', // Important for handling binary data
        },
        null,
        getUserDetails()
    )
    console.log(data);
    return data;
};

const getPDFFile = async (two_pager_id) => {
    const data = await axios.get(
        buildUrl(`2pager/view_v2/export_2pager_pdf?two_pager_id=${two_pager_id}`),
        {
            responseType: 'blob', // Important for handling binary data
        },
        null,
        getUserDetails()
    )
    console.log(data);
    return data;
};


export {
    view2PagerFinalLists,
    getFile,
    getPDFFile
};
