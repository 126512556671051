import axios from "axios";
import { environment } from "../../environment";

const buildUrl = (path) => {
  return environment.baseUrl + path;
};

const getUserDetails = () => {
  const response = JSON.parse(localStorage.getItem("userLoginInfo"));
  const data = {
    email_id: response.userEmail,
    access_token: response.access_token,
  };

  const options = {
    headers: data,
  };
  return options;
};

const getDashboardLists = async (options) => {
  const { data } = await axios.post(
    buildUrl("cim_api/cim/dashboard_cim_list"),
    options,
    getUserDetails()
  );
  return data;
};

const getDashboard2PagerViewLists = async (id) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(`cim_api/cim/dashboard_cim_2pager_list?cim_id=${id}`),
    null,
    userHeaders
  );
  return data;
};

const getAccessData = async (id) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(`/cim_api/cim_access/populate_access_data?cim_id=${id}`),
    null,
    userHeaders
  );
  return data;
};

const submitAccessData = async (parammeters) => {
  const userHeaders = getUserDetails();
  const { data } = await axios.post(
    buildUrl(`/cim_api/cim_access/cim_access_control`),
    parammeters,
    userHeaders
  );
  return data;
};

export {
  getDashboardLists,
  getDashboard2PagerViewLists,
  getAccessData,
  submitAccessData,
};
