import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "../reducer/dashboardSlice";
import twoPagerReducer from "../reducer/twoPagerSlice";

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    twoPager: twoPagerReducer,
  },
});
