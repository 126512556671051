import { InteractionType } from "@azure/msal-browser";
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { loginRequest } from "./azure/authConfig";

const PrivateRoute = ({ children }) => {
  
  const isAuthenticated = useIsAuthenticated();

 


  if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
